<div class="inner">
  <div class="main">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <!-- <app-header-one></app-header-one> -->
    <section
      class="section welcome-area bg-overlay subscribe-area h-100vh ptb_100"
    >
      <div class="container h-100">
        <div class="row align-items-center justify-content-center h-100">
          <div class="col-12 col-md-10 col-lg-8">
            <div class="subscribe-content text-center">
              <h1 class="text-white">Forgot your password?</h1>
              <p class="text-white my-4">
                Don't worry. Enter your email, we'll send you a password reset
                link to help you get back into your account.
              </p>
              <!-- Subscribe Form -->
              <form class="subscribe-form">
                <div class="form-group">
                  <input
                    type="email"
                    [formControl]="form.controls['email']"
                    class="form-control"
                    name="email"
                    id="exampleInputEmail1"
                    required
                    aria-describedby="emailHelp"
                    placeholder="Enter your email"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-lg btn-block"
                  (click)="resetPassword()"
                >
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
