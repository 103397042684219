import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discover-one',
  templateUrl: './discover-one.component.html',
  styleUrls: ['./discover-one.component.css']
})
export class DiscoverOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
