<div *ngIf="data.type !== 'image-preview'" class="modal-wrapper">
  <div class="modal-header">
    <div>{{ this.data.title }}</div>
  </div>

  <div class="text-wrapper">
    <form [formGroup]="form">
      <label
        class="form-label"
        for="reason"
        style="font-size: 16px; text-align: left"
        >Alasan (Wajib)
      </label>
      <textarea
        style="width: 100%"
        formControlName="problemType"
        rows="3"
        id="reason"
        class="form-control"
        [(ngModel)]="rejection_notes"
        placeholder="Minimal 5 karakter"
      ></textarea>
    </form>

    <div
      fxLayout="row"
      style="padding-top: 20px; padding-bottom: 10px; align-items: end"
    >
      <div>
        <button
          mat-raised-button
          (click)="closeDialog()"
          style="background-color: #e91e63; color: #ffffff"
        >
          Keluar
        </button>
      </div>
      <div style="padding-left: 15px">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!form.valid"
          [mat-dialog-close]="getFormValue()"
        >
          Batalkan
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data.type === 'image-preview'">
  <div
    matTooltip="Tutup"
    mat-dialog-close
    [matTooltipPosition]="'left'"
    style="
      position: fixed;
      top: 50px;
      right: 50px;
      background: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <mat-icon>close</mat-icon>
  </div>

  <img [src]="data.src" style="width: 400px; height: auto" />
</div>
