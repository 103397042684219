<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" src="assets/img/logo-white.png" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/logo.png" alt="sticky brand-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Home
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <a class="dropdown-item" href="/theme-one">Homepage-1</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-two">Homepage-2</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-three">Homepage-3</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-four">Homepage-4</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-five">Homepage-5</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-six">Homepage-6</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#features">Features</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Pages
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Inner Pages<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/pricing">Pricing</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/download">Download</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/subscribe">Newsletter</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/thank-you">Thank you</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/coming-soon">Coming Soon</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/error">404</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Blog Pages</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/blog-two-column">Blog- 2 Column</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-three-column">Blog- 3 Column</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-left-sidebar">Blog- Left Sidebar</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-right-sidebar">Blog- Right Sidebar</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Blog Details</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/blog-details-left-sidebar">Blog Details- Left Sidebar</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-details-right-sidebar">Blog Details- Right Sidebar</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Accounts<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/login">Login</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/signup">Signup</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/reset">Reset Password</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/reviews">Reviews</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/faq">FAQ</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/contact">Contact</a>
                            </li>
                            <li>
                                <a class="dropdown-item disabled" href="#">More Coming Soon</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#screenshots">Screenshots</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#pricing">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" src="assets/img/logo-white.png" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/logo.png" alt="sticky brand-logo">
        </a>
        

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Home
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li>
                                <a class="dropdown-item" href="/theme-one">Homepage-1</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-two">Homepage-2</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-three">Homepage-3</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-four">Homepage-4</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-five">Homepage-5</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/theme-six">Homepage-6</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#features">Features</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Pages
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Inner Pages<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/pricing">Pricing</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/download">Download</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/subscribe">Newsletter</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/thank-you">Thank you</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/coming-soon">Coming Soon</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/error">404</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Blog Pages</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/blog-two-column">Blog- 2 Column</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-three-column">Blog- 3 Column</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-left-sidebar">Blog- Left Sidebar</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-right-sidebar">Blog- Right Sidebar</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Blog Details</a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/blog-details-left-sidebar">Blog Details- Left Sidebar</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/blog-details-right-sidebar">Blog Details- Right Sidebar</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="javascript:;">Accounts<span class="badge badge-pill badge-warning ml-2">New</span></a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="/login">Login</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/signup">Signup</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="/reset">Reset Password</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/reviews">Reviews</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/faq">FAQ</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="/contact">Contact</a>
                            </li>
                            <li>
                                <a class="dropdown-item disabled" href="#">More Coming Soon</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#screenshots">Screenshots</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#pricing">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
<router-outlet></router-outlet>