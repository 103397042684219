import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "src/app/number.pipe";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { CheckServicePayload } from "src/app/components/models/checkout/checkout";
import { DialogAlert } from "src/app/components/accounts/profile/myprofile/detail-profile/detail-profile.component";

@Component({
  selector: "app-select-service",
  templateUrl: "./select-service.component.html",
  styleUrls: ["./select-service.component.css"],
})
export class SelectServiceComponent implements OnInit {
  @Output() selectServiceEvent = new EventEmitter<string>();
  isLoading: boolean = false;
  totalData: number = 0;
  pageEvent: PageEvent;
  faTruckFast = faTruckFast;
  services: any[];
  service_id: number;

  constructor(
    public dialogRef: MatDialogRef<SelectServiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private currencyFormat: NumberFormatPipe,
    private service: ApiService,
    private dialog: MatDialog
  ) { }

  // @Input() service_id: string

  ngOnInit(): void {
    // this.services = this.data.services;
    // this.service_id = this.data.service
    // this.service_id = this.data.selectedService;

    console.log("CONSOLE THIS DATA: ", this.data);
    console.log("------------------------------");
    console.log("CONSOLE SERVICE ID: ", this.services);
    this.getServiceList();
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  getServiceList() {
    this.isLoading = true;
    const data: CheckServicePayload = {
      location_id: this.data.location_id,
      // city_id: this.data.city_id,
      store_id: this.data.store_id,
      products: this.data.products,
    };
    console.log(data);
    this.service.checkListService(data).subscribe(
      (res) => {
        this.isLoading = false;
        this.services = res;
        console.log("Daftar service: ", this.services);
        this.totalData = res.total;
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: err.error.message,
          },
        });
        this.dialogRef.close();
      }
    );
  }

  onSelectService(service: any) {
    console.log(service, "when service selected");
    if (service) {
      this.dialogRef.close(service);
    } else {
      this.dialogRef.close(this.data.services[0]);
    }
  }
}
