<div class="homepage-3">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-welcome-three></app-welcome-three>
        <app-feature-three></app-feature-three>
        <app-work></app-work>
        <app-service-three></app-service-three>
        <app-discover-three></app-discover-three>
        <app-review-three></app-review-three>
        <app-pricing-two></app-pricing-two>
        <app-subscribe></app-subscribe>
        <app-download></app-download>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>