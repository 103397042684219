<!-- <div class="contact-box bg-white rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
    <mat-tab-group>
        <mat-tab label="Profil"> 
            
        </mat-tab>
        <mat-tab label="Alamat">
             
        </mat-tab>
        <mat-tab label="Pembayaran">
            
        </mat-tab>
    </mat-tab-group>  
</div> -->

<div class="contact-box bg-white rounded p-5 shadow-lg">
    <nav mat-tab-nav-bar>
        <a mat-tab-link style="color: #1d1d1d;" *ngFor="let link of navLinks"
        [routerLink]="link.link"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive">
       {{link.label}}</a>
    </nav>
    <router-outlet></router-outlet>
</div>


<!-- <div class="contact-box bg-white rounded p-5 shadow-lg">
    <nav mat-tab-nav-bar>
        <a mat-tab-link routerLink="my-profile" routerLinkActive="active">Profil </a>
        <a mat-tab-link routerLink="address" routerLinkActive="active">Alamat </a>
        <a mat-tab-link routerLink="payment" routerLinkActive="active">Pembayaran </a>
    </nav>
    <router-outlet></router-outlet>
</div> -->