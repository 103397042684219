<footer class="section inner-footer bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Footer Items -->
                <div class="footer-items text-center">
                    <!-- Logo -->
                    <a class="navbar-brand" href="#">
                        <img class="logo" src="assets/img/logo.png" alt="">
                    </a>
                    <p class="mt-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.</p>
                    <ul class="d-flex flex-column flex-sm-row justify-content-between">
                        <li class="py-2"><a href="#">Affiliate program</a></li>
                        <li class="py-2"><a href="#">Terms &amp; Conditions</a></li>
                        <li class="py-2"><a href="#">Privacy Policy</a></li>
                        <li class="py-2"><a href="#">Refund Policy</a></li>
                    </ul>
                    <!-- Copyright Area -->
                    <div class="copyright-area border-0 pt-3">
                        <p>Made with <i class="fas fa-heart"></i> By <a href="#">Theme Land</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>