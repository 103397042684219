import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-six',
  templateUrl: './service-six.component.html',
  styleUrls: ['./service-six.component.css']
})
export class ServiceSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
