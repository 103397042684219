import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class SocketsService {
  // SOCKETIO_SERVER = "http://localhost:3610"
  SOCKETIO_SERVER = "https://api.onmarket.id"

  //Create Socket Connection
  private socket = io(this.SOCKETIO_SERVER,
    {
      extraHeaders: {
        'Authorization': "Bearer " + localStorage.getItem("jwt")
      }
    });

  constructor() { }

  connect(): void {
    this.socket.connect()
  }

  disconnect(): void {
    this.socket.disconnect();
  }

  isConnected(): boolean {
    return this.socket.connected
  }

  emitEvent(eventName: string, data?: any): void {
    this.socket.emit(eventName, data);
  }

  onEvent(eventName: string): Observable<any> {
    return new Observable(observer => {
      this.socket.on(eventName, (data: any) => {
        observer.next(data);
      });
      return () => this.socket.off(eventName);
    });
  }

}
