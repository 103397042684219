import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { AddCart } from 'src/app/components/cart/cart';
import { Wishlist, wishlistData } from 'src/app/components/models/wishlist/wishlist';
import { NumberFormatPipe } from 'src/app/number.pipe';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  total: number
  pageSize = 12
  pageIndex = 0
  maxPageSize: any[] = []
  data: wishlistData[] = []
  isLoading: boolean = true

  constructor(private service: ApiService, private currencyFormat: NumberFormatPipe, private snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.fetchFavorite(true)
  }

  fetchFavorite(loading: boolean = false) {
    const payload = {
      limit: this.pageSize,
      page: this.pageIndex
    }
    this.isLoading = loading
    this.service.listWishlist(payload).subscribe((r: Wishlist) => {
      this.data = r.result
      this.total = r.total
      this.maxPageSize = Array(Math.ceil(r.total / 12));
      this.isLoading = false
    })

  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  numberPagination(index: number = 0) {
    this.pageIndex = index;
    this.fetchFavorite();
  }

  arrowPagination(arg: string) {
    if (arg === "previous") {
      this.pageIndex = this.pageIndex - 1;
    } else if (arg === "next") {
      this.pageIndex = this.pageIndex + 1;
    }
    this.fetchFavorite();
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 3500,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  delete(item: wishlistData) {
    this.service.updateWishlist({
      action: 'Delete',
      wishlist_id: item.wishlist_id,
      product_id: parseInt(item.product_id),
      variant_id: parseInt(item.variant_id)
    }).subscribe((r: any) => { this.openSnackBar(r.message, ''); this.fetchFavorite(false) })
  }

  addToCard(item: wishlistData) {
    const payload: AddCart = {
      store_id: parseInt(item.store_id),
      product_id: parseInt(item.product_id),
      variant_id: parseInt(item.variant_id),
      quantity: 1
    }

    this.service.addCart(payload).subscribe(r => {
      this.openSnackBar(r.message, '')
    })
  }

}
