<div class="homepage-3">
  <div class="main">
    <!-- <app-whatsapp-corner></app-whatsapp-corner> -->
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <!-- <app-full-banner></app-full-banner> -->
    <section id="features" class="section features-area style-two overflow-hidden pt_50">
      <div class="container">
        <!-- Section Title -->
        <div class="col-12 col-md-12 col-lg-12 my-3" style="padding: 0 !important">
          <div class="image-box icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
            <img loading="lazy" style="width: 100%;border-radius: 12px;"
              src="https://storage.onindonesia.id/onmarket/Banner on market.png" alt="" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 my-3 res-margin">
            <!-- Image Box -->
            <div class="image-box-banner text-left icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
              <img style="width: 100%;border-radius: 10px;"
                src="https://storage.onindonesia.id/onmarket/Banner on market 1.png" alt="" />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 my-3 res-margin">
            <!-- Image Box -->
            <div class="image-box-banner text-left icon-1 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
              <img style="width: 100%;border-radius: 10px;"
                src="https://storage.onindonesia.id/onmarket/Banner on market 2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <app-full-banner-demo></app-full-banner-demo> -->
    <!-- <app-category></app-category> -->
    <!-- <app-feature-tabs></app-feature-tabs> -->
    <!-- <app-feature-tabs-demo></app-feature-tabs-demo> -->
    <!-- <app-banner-section></app-banner-section> NO -->
    <!-- <app-product-recommendation></app-product-recommendation> -->
    <!-- <app-shop-by-collection></app-shop-by-collection> -->
    <!-- <app-promotion-slider></app-promotion-slider> -->
    <!-- <app-brand></app-brand> -->
    <!-- <app-highlight></app-highlight> -->
    <app-footer-one></app-footer-one>
  </div>
</div>