<div class="homepage-5">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-welcome-five></app-welcome-five>
        <app-feature-five></app-feature-five>
        <app-discover-five></app-discover-five>
        <app-service-five></app-service-five>
        <app-work></app-work>
        <app-screenshot-two></app-screenshot-two>
        <app-review-two></app-review-two>
        <app-pricing-three></app-pricing-three>
        <app-team></app-team>
        <app-download></app-download>
        <app-subscribe></app-subscribe>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>