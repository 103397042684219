import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SocketsService } from 'src/app/sockets.service';

type queryParams = {
  storeId: number
  product?: string
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  storeId: number = null
  chatLoading: boolean = false
  loggedinUser = null
  chats = []
  activeChat!: string
  activeUsername!: string
  activeLastOnline: any
  activeProfilePicture: string
  storeName: string
  storeUrl: string
  constructor(private route: ActivatedRoute, private apiService: ApiService, private socketService: SocketsService) {
  }

  ngOnInit(): void {
    this.loggedinUser = localStorage.getItem("username");
    this.socketService.connect()
    this.chatLoading = true
    // const data: queryParams = history.state
    this.route.queryParams.subscribe(query => {
      if (query.hasOwnProperty("d")) {
        const data: queryParams = JSON.parse(atob(atob(query.d)))
        this.storeId = data.storeId

        this.apiService.existingChat(this.storeId).subscribe(r => {
          if (this.socketService.isConnected) {
            this.socketService.emitEvent("get-chat-rooms", { exclude: this.storeId })
            // console.log(r)
            this.chats[0] = r
            this.setActive(r)
          }
        },
          err => { console.error(err); this.socketService.emitEvent("get-chat-rooms"); })
      }
      else {
        this.socketService.emitEvent("get-chat-rooms")

      }
    })

    this.socketService.onEvent("chat-rooms").subscribe(r => {
      console.log("chat-rooms", r)
      const response = r.data.chats.filter(item => item.is_store)
      this.chatLoading = false
      // console.log(this.chats.length)
      if (this.chats.length === 1) {
        console.log("ok")
        console.log(this.chats)
        if (this.chats[0].id !== response[0]?.id)
          this.chats = [...this.chats, ...response]
        else this.chats = response
      }
      else {
        this.chats = response
      }
    })

    console.log(this.chats)
  }

  ngOnDestroy() {
    this.socketService.disconnect()
  }

  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/img/store.png'
  }
  setActive(chat) {
    this.activeChat = chat.id
    this.activeUsername = chat.user
    this.storeName = chat.store_name
    this.activeProfilePicture = chat.image_url
    this.activeLastOnline = chat.last_online
    this.storeUrl = chat.store_url
    // console.log(chat)
  }

  back() {
    this.activeChat = null
  }

}
