<div class="homepage-3 inner inner-pages">
    <div class="main">
        <app-header-one></app-header-one>
        <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
            <div class="container h-100">
                <div class="row align-items-center justify-content-center h-100">
                    <div class="col-12 col-md-7">
                        <div class="welcome-intro text-center">
                            <h1 class="text-white">Subscribe to get updates!</h1>
                            <p class="text-white my-4">By subscribing, you are agree to our terms &amp; conditions. Majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form d-flex align-items-center mx-auto">
                                <input type="email" class="form-control" placeholder="Enter your Email">
                                <button type="submit" class="btn btn-bordered">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>