<section
  id="features"
  class="section features-area style-two overflow-hidden ptb_100"
>
  <div class="container">
    <!-- Section Title -->
    <div class="row justify-content-center">
      <div class="col-12 col-md-9 col-lg-8">
        <div class="text-center">
          <h2 class="mb-5 custom-text-title">Hubungi Kami</h2>
        </div>
      </div>
    </div>
    <span class="custom-text-subtitle" style="text-align: justify">
      Butuh bantuan atau ada saran untuk kami? Silakan hubungi kami melalui
      saluran komunikasi berikut:
    </span>
    <div class="row pt_50">
      <div class="col-12 col-md-6 col-lg-6 my-3 res-margin">
        <!-- Image Box -->
        <div
          class="image-box text-left icon-1 p-5 wow fadeInLeft"
          data-aos-duration="2s"
          data-wow-delay="0.4s"
        >
          <!-- Featured Image -->
          <div class="mb-3">
            <div class="row">
              <img
                class="avatar-sm"
                src="../../../../assets/svg/mail2.svg"
                alt=""
              />
              <span class="custom-text-title-description ml-2"
                >Email (24/7)</span
              >
            </div>
          </div>
          <!-- Icon Text -->
          <div class="icon-text">
            <span class="custom-text-description" style="text-align: justify">
              Email Partner di marketingonindonesia.id untuk informasi lebih
              lanjut tentang produk atau layanan On Market.
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 my-3 res-margin">
        <!-- Image Box -->
        <div
          class="image-box text-left icon-1 p-5 wow fadeInUp"
          data-aos-duration="2s"
          data-wow-delay="0.2s"
        >
          <!-- Featured Image -->
          <div class="mb-3">
            <div class="row">
              <img
                class="avatar-sm"
                src="../../../../assets/svg/phone2.svg"
                alt=""
              />
              <span class="custom-text-title-description ml-2"
                >Customer Service</span
              >
            </div>
          </div>
          <!-- Icon Text -->
          <div class="icon-text">
            <span class="custom-text-description" style="text-align: justify">
              Hubungi Customer Service di nomor (021) 8063 0065 (Senin–Jumat
              pada 09.00–18.00 WIB, kecuali hari libur).
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
