import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap, Router, UrlSegment } from '@angular/router';
import { NumberFormatPipe } from '../../../number.pipe';
import { DatePipe, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
  providers: [Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }, NumberFormatPipe]
})
export class ReviewComponent implements OnInit {

  store_id: any
  reviews: any
  storeInfos: any
  activeTab: any
  pageIndex: number = 0
  totalPageSize: any[] = []
  reviewPageIndex: number = 0
  reviewTotalPage: any[] = []
  params: ParamMap
  selectedIndex = 0

  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
    private router: Router,
    private location: Location
  ) {
    this.params = this.route.snapshot.queryParamMap
    this.pageIndex = 0
    this.store_id = this.params.get('store_id')
    this.service.storeInfo(this.store_id).subscribe(data => {
      this.storeInfos = data
      // console.log("PersonalStoreComponent-StoreInfo")
      // console.log(data)
    }, e => {
      console.log(e)
    })

    this.fetchReviews(this.store_id, this.reviewPageIndex)
   }

   fetchReviews(storeId: number, pageIndex: number = 0) {
    this.service.reviewList(null, pageIndex, storeId).subscribe(data => {
      this.reviews = data
      this.reviewTotalPage = Array(Math.round(data.total / 10))
      // console.log("PersonalStoreComponent-Review")
      // console.log(data)
    }, e => {
      console.log(e)
    })
  }

  // dateTransform(date) {
  //   return this.datepipe.transform(date, 'd MMMM yyyy HH:mm');
  // }

  ngOnInit(): void {
  }

  arrowPaginations(arg: string, number: number = 0) {
    if (arg === 'previous') {
      this.reviewPageIndex = this.reviewPageIndex - 1
    } else if (arg === 'next') {
      this.reviewPageIndex = this.reviewPageIndex + 1
    }
    this.fetchReviews(this.store_id, this.reviewPageIndex)
  }
  numberPaginations(index: number = 0) {
    this.reviewPageIndex = index
    this.fetchReviews(this.store_id, this.reviewPageIndex)
  }

  inputnumber = 0;

  plus() {
    this.inputnumber = this.inputnumber + 1;
  }
  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
    }
  }

}
