import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AddCart } from "./components/cart/cart";
import { CheckServicePayload } from "./components/models/checkout/checkout";
import { createReview, listReview } from "./components/models/review-and-rating/review-and-rating.interface";
import { UpdateWishlist } from "./components/models/wishlist/wishlist";

@Injectable()
export class ApiService {
  //DEMO
  // BASEURL = "https://test.onmarket.id/";
  // API_USERSTATE_SERVER = "https://demousapi.onindonesia.id/";
  // API_USERONDELIVERY_SERVER = "http://202.157.187.188:3400/";
  // API_OSAS_SERVER = "https://testapi.ondelivery.id/";
  // API_ONMARKET_SERVER = "https://testapi.onmarket.id/";
  // API_ONBOOKING_SERVER = "https://demoapisat.ondelivery.id/";

  //LOCAL
  // API_ONMARKET_SERVER = "http://192.168.1.121:3611/";
  // API_USERSTATE_SERVER = "http://169.254.19.85:3300/";
  // BASEURL = "http://localhost:4200/";

  //PROD
  // BASEURL = "https://gigas.id/";
  BASEURL = "https://onmarket.id/";
  API_USERSTATE_SERVER = "https://usapi.onindonesia.id/";
  API_USERONDELIVERY_SERVER = "https://onapps-api.onindonesia.id/";
  API_OSAS_SERVER = "https://api.ondelivery.id/";
  API_ONMARKET_SERVER = "https://api.onmarket.id/";
  API_ONBOOKING_SERVER = "https://apisat.ondelivery.id/";

  constructor(private http: HttpClient) { }

  isUpdateCart = new BehaviorSubject(true);
  deletedFromCart = new BehaviorSubject(true);
  login = new BehaviorSubject(true);

  getUser(uname: string, pwd: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      options: {
        nativeApp: false,
      },
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "auth",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  register(payload: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post<any>(this.API_USERSTATE_SERVER + "signup", payload, {
      headers: customHeaders,
    });
  }

  verify(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify", {
      headers: customHeaders,
    });
  }

  verifySignUp(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-sign-up", {
      headers: customHeaders,
    });
  }

  logOff(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "logoff", {
      headers: customHeaders,
    });
  }

  requestResetPwd(email: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      email: email,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "request-reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verifyLinkResetPwd(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-reset-pwd", {
      headers: customHeaders,
    });
  }

  resetPwd(password: string, token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const payload = {
      password: password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  /////////////////////////////

  verifyUserExist(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "verify-user-exist", {
      headers: customHeaders,
    });
  }

  verifyOTP(params: any, token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "verify-sign-up",
      params,
      {
        headers: customHeaders,
      }
    );
  }

  resendOTP(token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(
      this.API_USERSTATE_SERVER + "resend-otp-register",
      {
        headers: customHeaders,
      }
    );
  }

  // activateStore(token: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "activate-store", { headers: customHeaders })
  // }

  // editStore(token: string, image: File, name: string, category: string, description: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     image: image,
  //     name: name,
  //     category: category,
  //     description: description,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store", payload, { headers: customHeaders })
  // };

  // editStoreSchedule(token: string, open_days: string[], time: string[][]) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     status: false,
  //     schedule: {
  //       open_days: open_days,
  //       time: time,
  //     }
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-schedule", payload, { headers: customHeaders })
  // };

  // editStoreExpedition(token: string, expedition_setting_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = [{
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }, {
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }, {
  //     status: false,
  //     expedition_setting_id: expedition_setting_id,
  //   }]
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-expedition", payload, { headers: customHeaders })
  // };

  // editStoreAddress(token: string, address_id: number, store_address: boolean) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     address_id: address_id,
  //     store_address: false,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-address", payload, { headers: customHeaders })
  // };

  // addStoreFront(token: string, store_id: number, name: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //     name: name,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "add-store-front", payload, { headers: customHeaders })
  // };

  // editStoreFront(token: string, store_id: number, name: string) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //     name: name,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "edit-store-front", payload, { headers: customHeaders })
  // };

  // ////////////////////

  // listStoreFront(token: string, store_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //   }
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "list-store-front", { headers: customHeaders })
  // };

  // ///////////////////////

  // deleteStoreFront(token: string, store_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_id: store_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "delete-store-front", payload, { headers: customHeaders })
  // };

  // addFrontList(token: string, store_front_id: number, product_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_front_id: store_front_id,
  //     product_id: product_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "add-front-list", payload, { headers: customHeaders })
  // };

  // listFrontList(token: string, store_front_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     store_front_id: store_front_id,
  //   }
  //   return this.http.get<any>(this.API_USERSTATE_SERVER + "list-front-list", { headers: customHeaders })
  // };

  // deleteFrontList(token: string, front_list_id: number) {
  //   let customHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   });
  //   const payload = {
  //     front_list_id: front_list_id,
  //   }
  //   return this.http.post<any>(this.API_USERSTATE_SERVER + "delete-front-list", payload, { headers: customHeaders })
  // };

  storeInfo(store_id: any) {
    const params = new HttpParams().set("store_id", store_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/detail", {
      params: params,
    });
  }

  randomizeProducts() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const params = customHeaders
      ? {
        headers: customHeaders,
        params: {
          limiter: "gigas",
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/random",
      params
    );
  }

  mostSoldProducts(page) {
    const params = new HttpParams().set("page", page);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "product/most-sold", {
      params: params,
    });
  }

  searchProducts(queryTable, keyword: string) {
    keyword = keyword == null ? "" : keyword;
    queryTable.keyword = keyword;
    const payload = {
      limit: queryTable.limit,
      page: queryTable.page,
      keyword: queryTable.keyword,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/list",
      payload
    );
  }

  searchStores(queryTable, name: string) {
    name = name === null ? "" : name;
    queryTable.name = name;
    const payload = {
      limit: queryTable.limit,
      page: queryTable.page,
      name: queryTable.name,
    };

    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "store/search-store",
      payload
    );
  }

  trackingOrder(invoice: string) {
    const accessToken = localStorage.getItem("jwt");
    const params = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        invoiceNumber: invoice,
      },
    };
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/order/monitor-delivery",
      params
    );
  }

  searchBox(keyword: string, store_id?: number, type?: string) {
    let payload: any = {};
    // console.log(keyword, store_id, type);

    if (store_id != null && store_id !== undefined) {
      payload["store_id"] = store_id;
      payload["name"] = keyword;
      payload["type"] = "STORE";
    } else if (type != null && type !== undefined) {
      payload["name"] = keyword;
      payload["type"] = type;
    } else if (type == undefined) {
      payload["name"] = keyword;
      payload["type"] = "";
    }

    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/search-autocomplete",
      payload
    );
  }

  categoryList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const params = customHeaders
      ? {
        headers: customHeaders,
        params: {
          limiter: "gigas",
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/list/category",
      params
    );
  }

  categoryProduct(queryTable) {
    // queryTable.limiter = "gigas";
    // const params = new HttpParams().set("page", page).set("category_id", category_id)
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/list/category/products",
      queryTable
    );
  }

  productDetail(product_id) {
    const params = new HttpParams().set("product_id", product_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "product/detail", {
      params: params,
    });
  }

  voucherList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/list",
      null,
      { headers: customHeaders }
    );
  }

  voucherDetail(voucher_id: number) {
    const params = new HttpParams()
      .set("voucher_id", voucher_id)
      .set("type", "CUSTOMER");
    return this.http.get<any>(this.API_ONMARKET_SERVER + "voucher/detail", {
      params: params,
    });
  }

  reviewList(product_id, page, store_id) {
    //TODO
    product_id = product_id == null ? "" : product_id;
    store_id = store_id == null ? "" : store_id;
    const params = new HttpParams()
      .set("product_id", product_id)
      .set("page", page)
      .set("store_id", store_id);
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/review/list",
      { params: params }
    );
  }

  discussionList(product_id, page) {
    //TODO
    const params = new HttpParams()
      .set("product_id", product_id)
      .set("page", page);
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/discussion/list",
      { params: params }
    );
  }

  bestSeller(store_id: any) {
    const params = new HttpParams().set("store_id", store_id);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/best-seller", {
      params: params,
    });
  }

  storeProduct(content: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/list",
      content,
      {
        headers: customHeaders,
      }
    );
  }

  cartList() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    // const params = customHeaders
    //   ? {
    //       headers: customHeaders,
    //       params: {
    //         limiter: "gigas",
    //       },
    //     }
    //   : {};
    return this.http.get<any>(this.API_ONMARKET_SERVER + "cart/list", {
      headers: customHeaders,
    });
  }

  addCart(content: AddCart) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(this.API_ONMARKET_SERVER + "cart/add", content, {
      headers: customHeaders,
    });
  }

  deleteCart(varID: number) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      cart_id: varID,
    };
    return this.http.post<any>(this.API_ONMARKET_SERVER + "cart/delete", body, {
      headers: customHeaders,
    });
  }

  editCart(payload) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "cart/edit",
      payload,
      { headers: customHeaders }
    );
  }

  getStoreId() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/get-store-id", {
      headers: customHeaders,
    });
  }

  getStoreIdNew(store_name: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const params = new HttpParams().set("name", store_name);
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/get-store-id", {
      headers: customHeaders,
      params: params,
    });
  }

  ///____________ PROFILE ____________///

  userBasicProfile() {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "basic-profile", {
      headers: customHeaders,
    });
  }

  updateBasicProfile(payload, token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-basic-profile",
      payload,
      { headers: customHeaders }
    );
  }

  ChangeUserPassword(old_password: string, new_password: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt")!,
    });
    const payload = {
      old_password: old_password,
      new_password: new_password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-pwd",
      payload,
      { headers: customHeaders }
    );
  }

  getUserImage(username: string) {
    // const params = new HttpParams().set("username", username)
    // return this.http.get<any>(this.API_USERSTATE_SERVER + "get-profile-img/" + username)
    return this.API_USERSTATE_SERVER + "get-profile-img/" + username;
  }

  listUserAddress(queryTable) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/list",
      queryTable,
      params
    );
  }

  addUserAddress(payload: any, token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/add",
      payload,
      { headers: customHeaders }
    );
  }

  userAddressDetail(address_id: number) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/detail/" + address_id,
      params
    );
  }

  editUserAddress(payload: any) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/edit",
      payload,
      params
    );
  }

  deleteUserAddress(payload: any) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/delete",
      payload,
      params
    );
  }

  userMainAddress() {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/main",
      params
    );
  }

  destinationSearch(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!
    });
    const params = {
      search_dest: content,
    };
    return this.http.get<any>(
      this.API_OSAS_SERVER + "check-fee/destination/searchlanding",
      { headers: customHeaders, params: params }
    );
  }

  listOrderHistory(page: number, limit: number) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page: page,
          limit: limit,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/list/history",
      params
    );
  }

  listProcess(limit: number, page: number, process: string) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit: limit,
          page: page,
          process: process,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/list/orders",
      params
    );
  }

  confirmOrder(payload: string) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    const data = {
      invoiceNumber: payload,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/order/receive",
      data,
      params
    );
  }

  ///____________ CHECKOUT ____________///

  checkDeliveryFee(content: any): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};

    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "expedition/check-fee",
      content,
      params
    );
  }

  insuranceCheckFee(service_id: number, goods_value: number): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      service: service_id,
      goodsVal: goods_value,
    };
    return this.http.post<any>(this.API_OSAS_SERVER + "check-ins", body, {
      headers: customHeaders,
    });
  }

  calculateDiscount(
    code: string,
    price_before_discount: number
  ): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      code: code,
      price_before_discount: price_before_discount,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/calculate",
      body,
      { headers: customHeaders }
    );
  }

  redeemVoucher(code: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const body = {
      code: code,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "voucher/redeem",
      body,
      { headers: customHeaders }
    );
  }

  xenditCheckout(orderInfo): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/checkout",
      orderInfo,
      params
    );
  }

  invoiceDetail(payment_id: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/payment/status",
      { payment_id: payment_id },
      { headers: customHeaders }
    );
  }

  checkListService(payload: CheckServicePayload) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "expedition/check-fee",
      payload,
      { headers: customHeaders }
    );
  }
  ///____________ ORDER HISTORY ____________///

  waybillBasic(waybill: Array<string>) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!,
    });
    const payload = {
      searchMethod: "trackingID",
      searchTrackID: waybill,
    };
    // return this.http.post<any>(this.API_USERONDELIVERY_SERVER+"waybill-basic", JSON.stringify(payload), {headers: customHeaders})
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/waybill/tracking-scan",
      payload,
      { headers: customHeaders }
    );
  }

  cancelInvoice(payload: any): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          type: "CUSTOMER",
        },
      }
      : {};

    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/order/request-cancel",
      payload,
      params
    );
  }

  bookingDetail(booking_id: string): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization:
            "Basic " +
            btoa(
              "on-booking:iRiLiODOSV1X8WMVCThlOhpwnOYdsRYXiaJI3hIfeDZKaiOkKioUBQr1Y7PlnXhM"
            ),
        }),
        params: {
          booking_id: booking_id,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONBOOKING_SERVER + "booking/delivery/basic/detail",
      params
    );
  }

  ///____________ Checkout ____________///
  reviewOrder(payload: any) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "transaction/review",
      payload,
      params
    );
  }

  //___________________CHAT____________________
  existingChat(storeId: number): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get(this.API_ONMARKET_SERVER + `chat/existing-chat/${storeId}`, { headers: customHeaders })
  }



  ///__________________Rating and Review____________
  uploadImages(formData) {
    let customHeaders = new HttpHeaders({
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.post(this.API_ONMARKET_SERVER + 'review/media/upload/images', formData, {
      headers: customHeaders,
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadVideo(formData) {
    let customHeaders = new HttpHeaders({
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.post(this.API_ONMARKET_SERVER + 'review/media/upload/videos', formData, {
      headers: customHeaders,
      reportProgress: true,
      observe: 'events'
    })
  }

  addReview(payload: createReview[]) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.post(this.API_ONMARKET_SERVER + 'review', payload, { headers: customHeaders })
  }

  listReview(product_id: number, payload: listReview) {
    return this.http.post(this.API_ONMARKET_SERVER + `review/list/${product_id}`, payload)
  }


  //____________WISHLIST____________
  updateWishlist(payload: UpdateWishlist) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(this.API_ONMARKET_SERVER + 'wishlist/update', payload, { headers: customHeaders })
  }

  getWishlist(product_id: string) {
    const header = {
      "Content-Type": "application/json",
    }

    if (localStorage.getItem("jwt")) {
      header["Authorization"] = "Bearer " + localStorage.getItem("jwt")
    }

    let customHeaders = new HttpHeaders(header)

    return this.http.get(this.API_ONMARKET_SERVER + `wishlist/${product_id}`, { headers: customHeaders })
  }

  listWishlist(payload: { limit: number, page: number }) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(this.API_ONMARKET_SERVER + 'wishlist', payload, { headers: customHeaders })
  }

}
