import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  // NOTE: url untuk get profile image DEMO or LIVE
  // url: string = "https://demousapi.onindonesia.id/get-profile-img/";
  url: string = "https://usapi.onindonesia.id/get-profile-img/";

  imageUrl: string = this.url + localStorage.getItem("username");
  username: string;
  //!highlioght kategori, mei shop ilangin
  constructor(private service: ApiService) {
    this.service.userBasicProfile().subscribe(
      (data) => {
        this.username = data.username;
        // console.log("DetailProfileComponent-UserProfile")
        // console.log(data)
      },
      (e) => {
        console.log(e);
      }
    );
  }

  ngOnInit(): void {}

  useDefaultProfileImg() {
    this.imageUrl = "assets/img/user-avatar.png";
  }
}
