<!-- <mat-tab label="Ulasan"> -->
    <!-- <ng-template mat-tab-label>
        <a [routerLink]="['.']" [queryParams]="{ tab: 'review' }"
            queryParamsHandling="merge"></a>
    </ng-template> -->
    <div class="col-12">
        <div class="card p-3">
            <div class="card-body">
                <div class="col-12 d-flex flex-row flex-wrap">
                    <div class="col-6 ">
                        <h2>
                            {{storeInfos.rating}}
                            <span class="material-icons"
                                style="color: #ffc53e; font-size: 50px; padding :0px;">star</span>
                            <p>({{storeInfos.total_reviews}} Ulasan)</p>
                        </h2>
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar"
                                style="width: 80%" aria-valuenow="80" aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>

                    </div>
                    <div class="separator"></div>
                    <div class="col-4 ">
                        <div class="col-12 d-flex flex-row">
                            <div class="col-1 d-flex flex-row">
                                5
                            </div>
                            <div class="col-6 d-flex flex-row">
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                            </div>
                            <div class="col-5 total-rating">
                                (14 Ulasan)
                            </div>

                        </div>
                        <div class="col-12 d-flex flex-row">
                            <div class="col-1 d-flex flex-row">
                                4
                            </div>
                            <div class="col-6 d-flex flex-row">
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                            </div>
                            <div class="col-5 total-rating">
                                (14 Ulasan)
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-row">
                            <div class="col-1 d-flex flex-row">
                                3
                            </div>
                            <div class="col-6 d-flex flex-row">
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                            </div>
                            <div class="col-5 total-rating">
                                (14 Ulasan)
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-row">
                            <div class="col-1 d-flex flex-row">
                                2
                            </div>
                            <div class="col-6 d-flex flex-row">
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                            </div>
                            <div class="col-5 total-rating">
                                (14 Ulasan)
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-row">
                            <div class="col-1 d-flex flex-row">
                                1
                            </div>
                            <div class="col-6 d-flex flex-row">
                                <span class="material-icons"
                                    style="color: #ffc53e; padding :0px;">star</span>
                            </div>
                            <div class="col-5 total-rating">
                                (14 Ulasan)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mat-elevation-z1 pt-2">
            <div class="row" *ngFor="let review of reviews.reviews">
                <div class="col-12 d-flex flex-row flex-wrap">
                    <div class="col-lg-3 col-sm-6" style="padding: 0;;">
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div class="pr-3">
                                    <img class="image-costumer mr-3"
                                        src="assets/img/ss-5.jpg" alt="">
                                </div>
                                <div class="row">
                                    <h4 class="col-12" style="margin: 0px;">
                                        {{review.username}}
                                    </h4>
                                    <p class="col-12">
                                        <span class="material-icons"
                                            style="color: #ffc53e;">star</span>
                                        <span class="material-icons"
                                            style="color: #ffc53e;">star</span>
                                        <span class="material-icons"
                                            style="color: #ffc53e;">star</span>
                                        <span class="material-icons"
                                            style="color: #ffc53e;">star</span>
                                        <span class="material-icons"
                                            style="color: #ffc53e;">star</span>
                                    </p>

                                </div>
                            </div>
                            <p class="comment"><span
                                    class="material-icons mr-1">drive_file_rename_outline</span>{{review.created_at}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-9 col-sm-6">
                        <div class="d-flex flex-row justify-content-end">
                            <p class="comment mr-2">Variasi : {{review.variant_name}}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-end">
                            <div>
                                <img class="image-costumer mr-3" src="assets/img/ss-5.jpg"
                                    alt="">
                            </div>
                            <div>
                                <img class="image-costumer mr-3" src="assets/img/ss-5.jpg"
                                    alt="">
                            </div>

                            <div>
                                <img class="image-costumer mr-3" src="assets/img/ss-5.jpg"
                                    alt="">
                            </div>

                            <div>
                                <img class="image-costumer mr-3" src="assets/img/ss-5.jpg"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <p>{{review.description}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- TODO fix arrow pagination -->
            <!-- Pagination -->
            <ul class="pagination justify-content-center">
                <li class="px-1" [ngClass]="{'disabled': pageIndex === 0}">
                    <a href="javascript:void(0)" aria-label="Previous"
                        (click)="arrowPaginations('previous')">
                        <i class="fas fa-arrow-left"></i>
                    </a>
                </li>
                <li class="px-1" *ngFor="let page of totalPageSize;index as i"
                    [ngClass]="{'active': pageIndex === i}">
                    <a href="javascript:void(0)"
                        (click)="pageIndex = i;numberPaginations(i)">{{i+1}}</a>
                </li>
                <li [ngClass]="{'disabled': pageIndex  === totalPageSize.length - 1}">
                    <a href="javascript:void(0)" aria-label="Next"
                        (click)="arrowPaginations('next')">
                        <i class="fas fa-arrow-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
<!-- </mat-tab> -->