import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NumberFormatPipe } from "../../../number.pipe";
import {
  DatePipe,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { concatMap } from "rxjs/operators";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

interface FilterObj {
  price?: {
    min_price?: number;
    max_price?: number;
  };

  option?: string;
  location?: string;
  condition?: string;
}

interface QueryTable {
  // sort: null | Object;
  filters: FilterObj;
  page: number;
  limit: number;
  shop_category?: number;
  category_id?: number;
  store_id?: number;
  keyword?: string
  // promo?: null | boolean;
}

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.css"],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    NumberFormatPipe,
  ],
})
export class ProductComponent implements OnInit {
  product: any;
  storeProducts: any;
  isLoading: boolean = false;
  store_id: number;
  store_name: any;
  pageIndex: number = 0;
  limit: number = 20;
  totalPageSize: any[] = [];
  params: ParamMap;
  form: FormGroup;
  minPrice: string = null;
  maxPrice: string = null;
  option: string = "";
  queryTable: QueryTable = {
    filters: {},
    page: this.pageIndex,
    limit: this.limit,
    keyword: ''
  };


  constructor(
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    public datepipe: DatePipe,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.params = this.route.snapshot.queryParamMap;
    this.pageIndex = 0;
    this.store_name = this.params.get("store_name");
    if (this.params.get("keyword")) this.queryTable.keyword = this.params.get("keyword");
    if (this.params.get('page')) this.pageIndex = parseInt(this.params.get('page'))


    this.form = this.fb.group({
      minPrice: [null],
      maxPrice: [null],
    });

    this.fetchData();
  }

  ngOnInit(): void { }

  fetchData() {
    this.isLoading = true;
    this.service
      .getStoreIdNew(this.store_name)
      .pipe(
        concatMap((data) => {
          this.isLoading = false;
          this.queryTable.store_id = Number(data.store_id);
          return this.service.storeProduct(this.queryTable);
        })
      )
      .subscribe(
        (productData) => {
          this.isLoading = false;
          console.log("ini yang keambil");
          this.storeProducts = productData.products;
          this.totalPageSize = Array(Math.ceil(productData.total / 24));
        },
        (e) => {
          this.isLoading = false;
          console.log(e);
        }
      );
  }

  // fetchstoreProduct(
  //   store_id: number,
  //   pageIndex: number = 0,
  //   limit: number = 20
  // ) {
  //   const data = {
  //     store_id: store_id,
  //     page: pageIndex,
  //     limit: limit,
  //   };
  //   this.service.storeProduct(data).subscribe(
  //     (data) => {
  //       this.storeProducts = data.products;
  //       this.totalPageSize = Array(Math.round(data.total / 24) + 1);
  //       console.log("PersonalStoreComponent-storeProduct");
  //       // console.log(data)
  //     },
  //     (e) => {
  //       console.log(e);
  //     }
  //   );
  // }

  // fetchProducts() {
  //   // var keyword = this.params.get("keyword");
  //   // console.log(this.queryTable)
  //   this.service.storeProduct(this.queryTable).subscribe(
  //     (data) => {
  //       this.storeProducts = data.products;
  //       this.totalPageSize = Array(Math.round(data.total / 24) + 1);
  //       // console.log("SearchResultComponent")
  //       // console.log(data.products)
  //       // console.log("Raw")
  //       // console.log(data)
  //       // console.log(this.totalPageSize)
  //     },
  //     (e) => {
  //       console.log(e);
  //     }
  //   );
  // }

  get minsPrice() {
    return this.minPrice;
  }
  set minsPrice(v) {
    this.minPrice = v !== null ? "Rp" + v?.replace(/[^0-9.]/g, "") : null;
  }

  get maxsPrice() {
    return this.maxPrice;
  }
  set maxsPrice(v) {
    this.maxPrice = v !== null ? "Rp" + v?.replace(/[^0-9.]/g, "") : null;
  }

  onMinChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g, "");
    }

    this.minPrice = "Rp " + this.priceTransform(event);
  }

  onMaxChange(event: any) {
    if (!/^\d+$/.test(event)) {
      event = event.replace(/\D/g, "");
    }

    this.maxPrice = "Rp " + this.priceTransform(event);
  }

  openSnackBar(message: any, action: any) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  search() {
    const minPrice =
      this.form.value.minPrice !== null &&
        this.form.value.minPrice !== "" &&
        this.form.value.minPrice !== undefined &&
        this.form.value.minPrice.length > 3
        ? parseInt(this.form.value.minPrice.replace(/[^\d]/g, ""), 10)
        : null;

    const maxPrice =
      this.form.value.maxPrice !== null &&
        this.form.value.maxPrice !== "" &&
        this.form.value.maxPrice !== undefined &&
        this.form.value.maxPrice.length > 3
        ? parseInt(this.form.value.maxPrice.replace(/[^\d]/g, ""), 10)
        : null;

    if (minPrice !== null && maxPrice !== null && minPrice > maxPrice) {
      return this.openSnackBar(
        "Harga maksimum harus lebih besar dari harga minimum",
        "OK"
      );
    }

    this.queryTable.store_id = this.store_id;
    this.queryTable.filters = {
      price: {
        min_price: minPrice,
        max_price: maxPrice,
      },
      option: this.option,
    };

    if (this.option == "refresh") {
      this.form.controls["minPrice"].setValue(null);
      this.form.controls["maxPrice"].setValue(null);
      this.option = "";
    }

    // this.fetchProducts();
  }

  minPriceFilter(event: any) {
    // console.log(event.target.value)

    if (event.target.value?.length > 0) {
      this.queryTable.filters = {
        price: {
          min_price:
            this.form.value.minPrice !== null &&
              this.form.value.minPrice !== "" &&
              this.form.value.minPrice !== undefined &&
              this.form.value.minPrice.length > 3
              ? this.form.value.minPrice.replace(/[^\d]/g, "")
              : null,
          max_price:
            this.form.value.maxPrice !== null &&
              this.form.value.maxPrice !== "" &&
              this.form.value.maxPrice !== undefined &&
              this.form.value.maxPrice.length > 3
              ? this.form.value.maxPrice.replace(/[^\d]/g, "")
              : null,
        },
        option: this.option,
      };
      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = {};
    }
  }

  maxPriceFilter(event: any) {
    // console.log(event.target.value)
    if (event.target.value?.length > 0) {
      this.queryTable.filters = {
        price: {
          min_price:
            this.form.value.minPrice !== null &&
              this.form.value.minPrice !== "" &&
              this.form.value.minPrice !== undefined &&
              this.form.value.minPrice.length > 3
              ? this.form.value.minPrice.replace(/[^\d]/g, "")
              : null,
          max_price:
            this.form.value.maxPrice !== null &&
              this.form.value.maxPrice !== "" &&
              this.form.value.maxPrice !== undefined &&
              this.form.value.maxPrice.length > 3
              ? this.form.value.maxPrice.replace(/[^\d]/g, "")
              : null,
        },
        option: this.option,
      };
      // console.log(this.queryTable.filters)
    } else {
      this.queryTable.filters = {};
    }
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === "previous") {
      this.pageIndex = Math.max(0, this.pageIndex - 1);
    } else if (arg === "next") {
      this.pageIndex = Math.min(
        this.totalPageSize.length - 1,
        this.pageIndex + 1
      );
    }
    this.updateQueryAndFetchData();
    this.scrollToTop();
  }

  numberPagination(index: number = 0) {
    this.pageIndex = index;
    this.updateQueryAndFetchData();
    this.scrollToTop();
  }

  updateQueryAndFetchData() {
    this.queryTable.page = this.pageIndex;
    this.fetchData();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }
}
