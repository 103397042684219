import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-verify-phone-otp",
  templateUrl: "./verify-phone-otp.component.html",
  styleUrls: ["./verify-phone-otp.component.css"],
})
export class VerifyPhoneOtpComponent implements OnInit, OnDestroy {
  form: FormControl;
  otp: string = "";
  private countdownTimer: ReturnType<typeof setTimeout>;
  public counter: number = 600;
  registerData: any;
  tokenData: any;
  tokenLogin: any;
  constructor(private router: Router, private service: ApiService) {
    this.form = new FormControl("", [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]);
  }

  @ViewChild("panel") public panel: ElementRef;
  ngOnInit() {
    const storedData2 = localStorage.getItem("token");
    this.tokenData = storedData2 ? JSON.parse(storedData2) : [];

    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 300);

    this.startCountdown();
  }

  ngOnDestroy() {
    this.clearCountdown();
  }

  resendOtp() {
    // Add logic here to resend OTP
    // console.log("resend OTP");
    const token = this.tokenData.token;

    this.service.resendOTP(token).subscribe(
      (response) => {
        // console.log(response)
        this.tokenData.token = response.token;
        this.counter = 600;
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: `${error.error.message}!`,
        });
      }
    );
  }

  submitOtp() {
    // data parameter untuk submit OTP
    const token = localStorage.getItem("tokenDaftar");
    const params = {
      otp: this.form.value,
      options: {
        native: true,
      },
    };

    this.service.verifyOTP(JSON.stringify(params), token).subscribe(
      (response) => {
        // console.log(response)
        this.tokenLogin = response.token;
        localStorage.setItem("jwt", this.tokenLogin);
        // localStorage.setItem("username", decodedToken.user);

        // console.log(this.tokenLogin);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Verifikasi berhasil. Anda akan langsung dibawa ke halaman beranda. Silakan login untuk akses fitur penuh.",
          allowOutsideClick: false,
        }).then((result) => {
          // console.log("result", result);
          if (result.isConfirmed) {
            this.service.verifyUserExist(this.tokenLogin).subscribe(
              () => {
                setTimeout(() => {
                  this.router.navigate(["/"]);
                }, 2000);
              },
              (err) => {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: err.error.message,
                });
              }
            );
          }
        });
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: `${error.error.message}!`,
        });
      }
    );
  }

  startCountdown() {
    const expirationTimestamp = parseInt(localStorage.getItem("expired"), 10);
    const now = Math.floor(Date.now() / 1000);
    const remainingTime = Math.max(expirationTimestamp - now, 0);

    this.counter = Math.min(remainingTime, 10 * 60);

    this.countdownTimer = setInterval(() => {
      const minutes = Math.floor(this.counter / 60);
      const seconds = this.counter % 60;
      const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      this.counter--;

      if (this.counter < 0) {
        this.clearCountdown();
        document.getElementById("resendButton").removeAttribute("disabled");
        document.getElementById("counter").style.display = "none";
      } else {
        document.getElementById(
          "counter"
        ).textContent = `Kirim ulang OTP dalam ${formattedTime}`;
      }
    }, 1000);
  }

  clearCountdown() {
    clearInterval(this.countdownTimer);
  }

  closeOtpContainer(): void {
    // Add logic here to close the OTP container
    setTimeout(() => {
      this.router.navigate(["signup"]);
    }, 1500);
  }

  onInputChange(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    event.target.value = inputValue;
  }
}
