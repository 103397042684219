<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-details-left-sidebar></app-breadcrumb-blog-details-left-sidebar>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <aside class="sidebar">
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Search Widget -->
                                <div class="widget-content search-widget">
                                    <form action="#">
                                        <input type="text" placeholder="Enter your keywords">
                                    </form>
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Category Widget -->
                                <div class="accordions widget catagory-widget" id="cat-accordion">
                                    <div class="single-accordion blog-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion1">Categories
                                            </a>
                                        </h5>
                                        <!-- Category Widget Content -->
                                        <div id="accordion1" class="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                            <!-- Category Widget Items -->
                                            <ul class="widget-items">
                                                <li><a href="#" class="d-flex p-3"><span>Web Design</span><span class="ml-auto">(14)</span></a></li>
                                                <li><a href="#" class="d-flex p-3"><span>App Landing</span><span class="ml-auto">(32)</span></a></li>
                                                <li><a href="#" class="d-flex active p-3"><span>Wordpress</span><span class="ml-auto">(27)</span></a></li>
                                                <li><a href="#" class="d-flex p-3"><span>UI Design</span><span class="ml-auto">(18)</span></a></li>
                                                <li><a href="#" class="d-flex p-3"><span>Bootstrap</span><span class="ml-auto">(15)</span></a></li>
                                                <li><a href="#" class="d-flex p-3"><span>Web Template</span><span class="ml-auto">(29)</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Post Widget -->
                                <div class="accordions widget post-widget" id="post-accordion">
                                    <div class="single-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion2">Popular Post
                                            </a>
                                        </h5>
                                        <!-- Post Widget Content -->
                                        <div id="accordion2" class="accordion-content widget-content collapse show" data-parent="#post-accordion">
                                            <!-- Post Widget Items -->
                                            <ul class="widget-items">
                                                <li>
                                                    <a href="#" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="align-self-center" src="assets/img/avatar-1.png" alt="">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">05 Feb, 2018</p>
                                                            <h6>Promote Your Apps With sApp</h6>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="align-self-center" src="assets/img/avatar-2.png" alt="">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">09 Apr, 2018</p>
                                                            <h6>Sell Your Product Like Never Before</h6>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="align-self-center" src="assets/img/avatar-3.png" alt="">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">13 Jul, 2018</p>
                                                            <h6>Built For Passionate People</h6>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" class="single-post align-items-center align-items-lg-start media p-3">
                                                        <!-- Post Thumb -->
                                                        <div class="post-thumb avatar-md">
                                                            <img class="align-self-center" src="assets/img/avatar-4.png" alt="">
                                                        </div>
                                                        <div class="post-content media-body pl-3">
                                                            <p class="post-date mb-2">03 Oct, 2018</p>
                                                            <h6>Get Unlimited Offers &amp; Plans</h6>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Single Widget -->
                            <div class="single-widget">
                                <!-- Tags Widget -->
                                <div class="accordions widget tags-widget" id="tags-accordion">
                                    <div class="single-accordion blog-accordion">
                                        <h5>
                                            <a role="button" class="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion3">Popular Tags
                                            </a>
                                        </h5>
                                        <!-- Tags Widget Content -->
                                        <div id="accordion3" class="accordion-content widget-content collapse show" data-parent="#tags-accordion">
                                            <!-- Tags Widget Items -->
                                            <div class="widget-content tags-widget-items pt-2">
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">sApp</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Bootstrap</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">IOS</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Android</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Landing Page</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Business</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Fashion</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Media</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Lifestyle</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Wordpress</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Web Design</a>
                                                <a href="#" class="d-inline-block mt-2 mr-1 px-2 py-1">Mobile App</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div class="col-12 col-lg-9">
                        <!-- Single Blog Details -->
                        <article class="single-blog-details">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <a href="#"><img src="assets/img/blog-2.jpg" alt=""></a>
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content sApp-blog">
                                <!-- Meta Info -->
                                <div class="meta-info d-flex flex-wrap align-items-center py-2">
                                    <ul>
                                        <li class="d-inline-block p-2">By <a href="#">Anna Swford</a></li>
                                        <li class="d-inline-block p-2"><a href="#">05 Feb, 2018</a></li>
                                        <li class="d-inline-block p-2"><a href="#">2 Comments</a></li>
                                    </ul>
                                    <!-- Blog Share -->
                                    <div class="blog-share ml-auto d-none d-sm-block">
                                        <!-- Social Icons -->
                                        <div class="social-icons d-flex justify-content-center">
                                            <a class="bg-white facebook" href="#">
                                                <i class="fab fa-facebook-f"></i>
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a class="bg-white twitter" href="#">
                                                <i class="fab fa-twitter"></i>
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a class="bg-white google-plus" href="#">
                                                <i class="fab fa-google-plus-g"></i>
                                                <i class="fab fa-google-plus-g"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Blog Details -->
                                <div class="blog-details">
                                    <h3 class="blog-title py-2 py-sm-3"><a href="#">How to grow up your business with sApp</a></h3>
                                    <p class="d-none d-sm-block">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                                    <p class="d-block d-sm-none">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.</p>
                                    <blockquote class="blockquote px-4 py-3 my-3 ml-4">
                                        <p class="d-none d-sm-block">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
                                        <p class="d-block d-sm-none">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </blockquote>
                                    <p class="d-none d-sm-block">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.</p>
                                    <p class="d-block d-sm-none">On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.</p>
                                </div>
                            </div>
                            <!-- Blog Comments -->
                            <div class="blog-comments">
                                <!-- Admin -->
                                <div class="admin media py-4">
                                    <!-- Admin Thumb -->
                                    <div class="admin-thumb avatar-lg">
                                        <img class="rounded-circle" src="assets/img/avatar-1.png" alt="">
                                    </div>
                                    <!-- Admin Content -->
                                    <div class="admin-content media-body pl-3 pl-sm-4">
                                        <h4 class="admin-name mb-2"><a href="#">Anna Swford</a></h4>
                                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock Virginia.</p>
                                    </div>
                                </div>
                                <!-- Comments -->
                                <div class="comments my-5">
                                    <!-- Comments Title -->
                                    <h3 class="comments-title text-primary text-uppercase text-right mb-3">Recent Comments</h3>
                                    <!-- Single Comments -->
                                    <div class="single-comments media p-3 p-lg-4">
                                        <!-- Comments Thumb -->
                                        <div class="comments-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar-2.png" alt="">
                                        </div>
                                        <!-- Comments Content -->
                                        <div class="comments-content media-body pl-3">
                                            <h5 class="d-flex mb-2">
                                                <a href="#">Jonayed Hasan</a>
                                                <a href="#" class="ml-auto">Reply</a>
                                            </h5>
                                            <p class="d-none d-lg-block">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                                            <p class="d-block d-lg-none">The standard chunk of Lorem Ipsum used since the 1500s.</p>
                                        </div>
                                    </div>
                                    <!-- Single Comments -->
                                    <div class="single-comments media p-3 p-lg-4">
                                        <!-- Comments Thumb -->
                                        <div class="comments-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar-3.png" alt="">
                                        </div>
                                        <!-- Comments Content -->
                                        <div class="comments-content media-body pl-3">
                                            <h5 class="d-flex mb-2">
                                                <a href="#">Jassica William</a>
                                                <a href="#" class="ml-auto">Reply</a>
                                            </h5>
                                            <p class="d-none d-lg-block">All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                                            <p class="d-block d-lg-none">All the Lorem Ipsum generators on the Internet tend to repeat.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Blog Contact -->
                            <div class="blog-contact mt-5">
                                <!-- Contact Title -->
                                <h3 class="comments-title text-primary text-uppercase text-right mb-3">Post your Comments</h3>
                                <!-- Comment Box -->
                                <div class="contact-box comment-box">
                                    <form method="POST" action="#">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-bordered" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
        <app-footer-two></app-footer-two>
    </div>
</div>