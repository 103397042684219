import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discover-three',
  templateUrl: './discover-three.component.html',
  styleUrls: ['./discover-three.component.css']
})
export class DiscoverThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
