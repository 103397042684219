<div class="blog" style="overflow-x: hidden">
  <div class="main circle-background">
    <app-scrollup></app-scrollup>
    <app-whatsapp-corner></app-whatsapp-corner>
    <!-- <app-message-corner></app-message-corner> -->
    <app-main-header></app-main-header>
    <app-breadcrumb-blog-details-left-sidebar></app-breadcrumb-blog-details-left-sidebar>
    <app-counter></app-counter>
    <section class="counter-area">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8">
          <div class="section-heading text-center">
            <h2 class="mb-5">Official Partner</h2>
            <div>
              <ngx-fast-marquee [speed]="20" [maskPercentage]="40" direction="right" [pauseOnHover]="true">
                <ng-container *ngFor="let image of imagesPartner">
                  <div style="margin: 0 20px" class="d-flex align-items-center">
                    <img [src]="image" alt="Brand Image" style="margin: auto;" />
                  </div>
                </ng-container>
              </ngx-fast-marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section counter-area ptb_50">
      <div class="col-12">
        <div class="section-heading text-center">
          <h2 class="mb-5">Our Seller</h2>
          <div>
            <ngx-fast-marquee [speed]="20" [maskPercentage]="40" direction="left" [pauseOnHover]="true">
              <ng-container *ngFor="let image of imagesSeller">
                <div style="margin: 0 20px" class="d-flex align-items-center">
                  <img [src]="image" alt="Brand Image" />
                </div>
              </ng-container>
            </ngx-fast-marquee>
          </div>
          <!-- <div class="pt-5">
            <ngx-fast-marquee [speed]="20" [maskPercentage]="40" direction="left" [pauseOnHover]="true">
              <ng-container *ngFor="let image of imagesPartner">
                <div style="margin: 0 20px">
                  <img [src]="image" alt="Brand Image" />
                </div>
              </ng-container>
            </ngx-fast-marquee>
          </div> -->
        </div>
      </div>
    </section>
    <section class="section counter-area ptb_50">
      <div class="col-12">
        <div class="section-heading text-center">
          <h2 class="mb-5">What People Said</h2>
        </div>
        <div class="reviews-wrapper" #slider>
          <div class="reviews-container">
            <div class="single-review card" *ngFor="let review of reviews">
              <div class="card-top p-4">
                <h4 class="text-primary mt-4 mb-3">{{ review.title }}</h4>
                <div class="review-text">
                  <p>{{ review.text }}</p>
                </div>
              </div>
              <div class="reviewer media bg-gray p-4">
                <div class="reviewer-thumb">
                  <img class="avatar-lg radius-100" [src]="review.image" alt="" />
                </div>
                <div class="reviewer-meta media-body align-self-center ml-4">
                  <h5 class="reviewer-name color-primary mb-2">
                    {{ review.name }}
                  </h5>
                  <h6 class="text-secondary fw-6">{{ review.position }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="pt_50"></div>
    <!-- <app-feature-five></app-feature-five> -->
    <app-footer-one></app-footer-one>
  </div>
</div>