<div class="d-none d-lg-block"></div>
<footer
  class="footer-area height-emulator text-white"
  style="border-top-right-radius: 50px; border-top-left-radius: 50px"
>
  <!-- Footer Top -->
  <div class="footer-top pt-2 pb-5">
    <div class="container mt-5">
      <div class="row d-flex">
        <div class="col-12 col-sm-6 col-lg-3">
          <!-- Footer Items -->
          <ul class="service-list">
            <!-- Single Service -->
            <div class="footer-items">
              <h3 class="footer-title mb-3">Info Kontak</h3>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <img
                    src="../../../../assets/svg/phone.svg"
                    alt="Phone Icon"
                  />
                </div>
                <div class="service-text media-body">
                  <p>(021) 50555007</p>
                </div>
              </li>
              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <img
                    src="../../../../assets/svg/company.svg"
                    alt="Company Icon"
                  />
                </div>
                <div class="service-text media-body">
                  <p>
                    Rukan SCBR, Jalan Boulevard Raya Jalan Agung Sedayu City
                    No.2
                  </p>
                  <p>Cakung Barat, Kec. Cakung</p>
                  <p>Jakarta Timur, ID 14140</p>
                </div>
              </li>

              <li class="single-service media py-2">
                <div class="icon pr-3">
                  <img src="../../../../assets/svg/mail.svg" alt="Mail Icon" />
                </div>
                <div class="service-text media-body">
                  <a href="mailto:marketing@onindonesia.id">
                    {{ "marketing@onindonesia.id" }}
                  </a>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 mb-5"></div>
        <div class="col-12 col-sm-6 col-lg-3"></div>

        <div class="col-12 col-sm-6 col-lg-3 mb-4">
          <h3 class="footer-title">Social Media</h3>
          <div class="social-icons d-flex mb-3">
            <a
              href="https://www.youtube.com/@ondeliveryindonesia"
              target="_blank"
            >
              <img
                src="../../../../assets/svg/youtube.svg"
                alt="Youtube Icon"
                class="social-icon"
              />
            </a>
            <a href="https://tiktok.com/@ondelivery.id" target="_blank">
              <img
                src="../../../../assets/svg/tiktok.svg"
                alt="Tiktok Icon"
                class="social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/ondeliveryid/?hl=en"
              target="_blank"
            >
              <img
                src="../../../../assets/svg/instagram.svg"
                alt="Instagram Icon"
                class="social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/ondeliveryid/?hl=en"
              target="_blank"
            >
              <img
                src="../../../../assets/svg/linkedin.svg"
                alt="Linkedin Icon"
                class="social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/ondeliveryid/?hl=en"
              target="_blank"
            >
              <img
                src="../../../../assets/svg/twitter.svg"
                alt="Twitter Icon"
                class="social-icon"
              />
            </a>
          </div>

          <div class="button-group store-buttons store-black d-flex flex-wrap">
            <a
              href="https://play.google.com/store/apps/details?id=com.onindonesia.onmapps"
            >
              <img src="assets/img/google-play-black.png" alt="" />
            </a>
            <a href="#">
              <img
                style="height: 35.88"
                src="assets/img/app-store-black.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="copyright-area py-4">
            <div class="copyright-right">
              &copy; Copyrights 2024 ONMARKET. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
