<div class="inner inner-pages">
    <div class="main">
        <app-header-one></app-header-one>
        <section id="home" class="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
            <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-12 col-md-8">
                        <div class="welcome-intro">
                            <h1 class="text-white">Thank you for downloading sApp</h1>
                            <p class="text-white my-4">We are continuously creating new stuff for sApp and trying to keep it up to date with the new technologies that might appear along the development process. We appreciate each of you who downloaded or purchased our theme and we take your suggestions into consideration every time.</p>
                            <a href="index.html" class="btn sApp-btn text-uppercase">Go to Homepage</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>