<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 order-2 order-md-1">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb mx-auto">
                    <img src="assets/img/welcome_mockup_2.png" alt="">
                </div>
            </div>
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-6 order-1 order-md-2">
                <div class="welcome-intro px-0 px-lg-4">
                    <h1 class="text-white font-italic mb-2 mb-md-3">sApp</h1>
                    <h3 class="text-white text-capitalize">Premium App Landing page</h3>
                    <p class="text-white mt-3 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="#">
                            <img src="assets/img/google-play.png" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app-store.png" alt="">
                        </a>
                    </div>
                    <span class="d-inline-block text-white fw-3 font-italic mt-3">* Available on iPhone, iPad and all Android devices</span>
                </div>
            </div>
        </div>
    </div>
</section>