import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-one',
  templateUrl: './pricing-one.component.html',
  styleUrls: ['./pricing-one.component.css']
})
export class PricingOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
