import { Component, ElementRef, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InvoiceItem, InvoicePayment } from 'src/app/components/models/order-management/order-management';
import { NumberFormatPipe } from 'src/app/number.pipe';
import { SocketsService } from 'src/app/sockets.service';

type queryParams = {
  storeId: number
  product?: string
}

type productDetail = {
  productId: number
  variantId: number
  productName: string
  variantName: string
  price: number
  productImage: string
  url: string
}

type order = {
  invoice: string
  invoice_due_date: string
  invoice_items: InvoiceItem[]
  invoice_notes: string
  invoice_status: string
  invoice_payment: InvoicePayment
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnChanges {
  @Input() chatId: string | null = null;
  @Input() username: string | null = null;
  @Input() storename: string | null = null;
  @Input() activeProfilePicture: string | null = null;
  @Input() activeLastOnline: string | null = null;
  @Input() storeUrl: string | null = null;
  @Output() backClicked = new EventEmitter<void>();



  loadMessageSubscription: Subscription
  newMessageSubscription: Subscription
  onlineSubscription: Subscription
  markReadSubscription: Subscription
  scrollContainer: HTMLElement;
  onlineStatus: string
  lastScrollPos: number
  message = [

  ]

  fetchLoading: boolean
  loggedinUser: string
  newMessage = '';
  page: number = 1
  pageSize: number = 25
  totalPage: number = 0
  change: boolean
  chatPriority: boolean = false

  productMessageDetail: productDetail
  orderMessage: order

  constructor(private elementRef: ElementRef, private currencyFormat: NumberFormatPipe, private route: ActivatedRoute, private socketService: SocketsService, private sanitizer: DomSanitizer) {

  }

  ngOnChanges() {
    // console.log(
    //   this.formatLastOnline(dayjs().diff(this.activeLastOnline, "minute"))
    // )
    this.newMessage = null
    this.productMessageDetail = null
    this.orderMessage = null

    this.loggedinUser = localStorage.getItem("username");
    this.route.queryParams.subscribe(query => {
      if (query.hasOwnProperty("d")) {
        const data: queryParams = JSON.parse(atob(atob(query.d)))
        if (data?.storeId)
          this.chatPriority = true
      }
    })

    this.change = true

    // console.log(this.mockMessage.length)
    this.page = 1
    if (this.socketService.isConnected) {
      this.fetchLoading = true
      this.socketService.emitEvent('load-message', {
        chatId: this.chatId,
        page: this.page - 1,
        pageSize: this.pageSize
      });
      this.socketService.emitEvent('mark-chat-as-read', { roomId: this.chatId })
    }
  }
  ngOnInit(): void {
    if (history.state.product) {
      this.productMessageDetail = {
        productId: history.state.productId,
        variantId: history.state.variantId,
        productName: history.state.productName,
        variantName: history.state.variantName,
        price: history.state.price,
        productImage: history.state.productImage,
        url: history.state.product
      }

      console.log(this.productMessageDetail)
    }

    if (history.state.invoice) {
      this.orderMessage = {
        invoice: history.state.invoice,
        invoice_due_date: history.state.invoice_due_date,
        invoice_items: history.state.invoice_items,
        invoice_notes: history.state.invoice_notes,
        invoice_status: history.state.invoice_status,
        invoice_payment: history.state.invoice_payment,
      }
    }

    if (this.socketService.isConnected) {
      this.loadMessageSubscription = this.socketService.onEvent('messages').subscribe((message) => {
        console.log('messages', message)
        if (this.change) this.message = message.data.chats
        else
          this.message.push(...message.data.chats)
        this.totalPage = message.data.total_pages
        // console.log(this.message.length)
        this.change = false
        if (!this.chatPriority)
          this.socketService.emitEvent('mark-chat-as-read', { roomId: this.chatId })
        this.fetchLoading = false
      })

      this.newMessageSubscription = this.socketService.onEvent('message').subscribe((message) => {
        // console.log(message.data, "messages")
        if (message.success) {
          if (message.data.from !== this.loggedinUser && message.data.from === this.username) {
            // this.playNotificationSound()
          }
          this.message = [message.data, ...this.message]
          this.chatPriority = false
          this.socketService.emitEvent('mark-chat-as-read', { roomId: this.chatId })
        }
      })

      this.onlineSubscription = this.socketService.onEvent('online-status').subscribe((message) => {
        this.onlineStatus = message

      })

      this.markReadSubscription = this.socketService.onEvent('chat-read').subscribe(() => {
        this.message.map((item) => item.read = true)
      })
    }

  }

  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/img/store.png'
  }

  ngOnDestroy() {
    this.newMessageSubscription.unsubscribe()
    this.loadMessageSubscription.unsubscribe()
    this.onlineSubscription.unsubscribe()
    this.markReadSubscription.unsubscribe()
  }


  formatMessage(message: string): SafeHtml {
    let formattedContent = message.replace(/\n/g, ' <br>');
    const urlPattern = /((http|https):\/\/[^\s]+)/g;
    formattedContent = formattedContent.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
    return this.sanitizer.bypassSecurityTrustHtml(formattedContent);
  }


  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (this.newMessage?.replace(/\n/g, '').trim().length === 0) event.preventDefault()

      this.handleSendMessage()

    }
  }

  removeEmptyLines(text): string {
    const lines = text.split('\n');

    let firstNonEmptyIndex = 0;
    while (firstNonEmptyIndex < lines.length && lines[firstNonEmptyIndex].trim() === '') {
      firstNonEmptyIndex++;
    }

    const trimmedLines = lines.slice(firstNonEmptyIndex);

    return trimmedLines.join('\n');
  }


  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  handleSendMessage() {
    if (this.productMessageDetail) {
      this.socketService.emitEvent('send-message', {
        to: this.username,
        room_id: this.chatId,
        type: 'product',
        content: {
          product_id: Number(this.productMessageDetail.productId),
          variant_id: Number(this.productMessageDetail.variantId),
          product_name: this.productMessageDetail.productName,
          variant_name: this.productMessageDetail.variantName,
          product_image: this.productMessageDetail.productImage,
          price: this.productMessageDetail.price,
          url: this.productMessageDetail.url
        }
      });


      setTimeout(() => {
        const cleanedMessage = this.newMessage?.replace(/\n/g, '').trim();
        if (cleanedMessage.length > 0) {
          if (this.socketService.isConnected) {
            this.socketService.emitEvent('send-message', {
              to: this.username,
              type: 'text',
              room_id: this.chatId,
              content: {
                text: this.removeEmptyLines(this.newMessage)
              }
            });
            this.newMessage = '';
          }
        }
      }, 100);

      this.productMessageDetail = null

    }
    else if (this.orderMessage) {
      this.socketService.emitEvent('send-message', {
        to: this.username,
        type: 'invoice',
        room_id: this.chatId,
        content: {
          invoice_number: this.orderMessage.invoice
        }
      });

      setTimeout(() => {
        const cleanedMessage = this.newMessage?.replace(/\n/g, '').trim();
        if (cleanedMessage.length > 0) {
          if (this.socketService.isConnected) {
            this.socketService.emitEvent('send-message', {
              to: this.username,
              room_id: this.chatId,
              type: 'text',
              content: {
                text: this.removeEmptyLines(this.newMessage)
              }
            });
            this.newMessage = '';
          }
        }
      }, 100);

      this.orderMessage = null
    }
    else {
      const cleanedMessage = this.newMessage?.replace(/\n/g, '').trim();
      if (cleanedMessage?.length > 0) {
        if (this.socketService.isConnected) {
          this.socketService.emitEvent('send-message', {
            to: this.username,
            room_id: this.chatId,
            type: 'text',
            content: {
              text: this.removeEmptyLines(this.newMessage)
            }
          });
          this.newMessage = '';
        }
      }
    }
  }

  handleFileInput(file: FileList) {
    const fileToCompress = file.item(0);
    if (fileToCompress) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');


          const maxWidth = 800;
          const maxHeight = 600;

          let width = img.width;
          let height = img.height;


          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }


          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, 0, 0, width, height);

          const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);

          // console.log(compressedDataUrl);
          if (this.socketService.isConnected) {
            this.socketService.emitEvent('send-message', {
              to: this.username,
              room_id: this.chatId,
              type: 'image',
              content: {
                image_url: compressedDataUrl
              }
            });
          }

        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(fileToCompress);
    }
  }



  ngAfterViewInit(): void {
    this.scrollContainer =
      this.elementRef.nativeElement.querySelector(".message-container");
    this.scrollContainer.addEventListener("scroll", this.onScroll.bind(this));
    // this.scrollToBottom();
  }


  onScroll() {
    const scrollPos =
      this.scrollContainer.clientHeight - this.scrollContainer.scrollTop;
    const threshold = 500;
    const containerHeight = this.scrollContainer.scrollHeight;
    // console.log(containerHeight - scrollPos)
    if (containerHeight - scrollPos < threshold) {
      if (!this.fetchLoading && this.page < this.totalPage && scrollPos - this.lastScrollPos > 0) {
        this.page = this.page + 1
        if (this.socketService.isConnected) {
          this.fetchLoading = true
          this.socketService.emitEvent('load-message', {
            chatId: this.chatId,
            page: this.page - 1,
            pageSize: this.pageSize
          });
        }
      }
    }
    this.lastScrollPos = scrollPos
  }

  removeProduct() {
    this.productMessageDetail = null
  }

  removeOrder() {
    this.orderMessage = null
  }

  viewImage(imageUrl: string): void {
    if (imageUrl.startsWith('data:image')) {
      const base64Data = imageUrl.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      const objectURL = URL.createObjectURL(blob);

      const newWindow = window.open(objectURL, '_blank');
      if (newWindow) {
        newWindow.onload = () => {

          URL.revokeObjectURL(objectURL);
        };
      }
    }
  }

  onBackClick() {
    this.backClicked.emit();
  }

  statusTransform(status: string) {
    switch (status) {
      case "waiting_confirmation":
        return "Menunggu Konfirmasi";
      case "process":
        return "Diproses";
      case "shipping":
        return "Dikirim";
      case "delivered":
        return "Sampai ditujuan";
      case "canceled":
        return "Dibatalkan";
      case "cancel_request":
        return "Meminta Pembatalan";
      case "done":
        return "Pesanan Selesai";
    }
  }

  // playNotificationSound() {
  //   if (this.notificationSound) {
  //     this.notificationSound.play().catch(error => {
  //       console.error('Error playing sound:', error);
  //     });
  //   }
  // }
}
