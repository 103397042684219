import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-two',
  templateUrl: './pricing-two.component.html',
  styleUrls: ['./pricing-two.component.css']
})
export class PricingTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
