<section id="features" class="section features-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Why choose sApp?</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-tap"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInUp" data-aos-duration="2s" data-wow-delay="0.2s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-fingerprint"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-notification"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.8s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-place"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInUp" data-aos-duration="2s" data-wow-delay="0.4s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-settings"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Icon Box -->
                <div class="icon-box text-center p-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.8s">
                    <!-- Featured Icon -->
                    <div class="featured-icon mb-3">
                        <span class="flaticon-language"></span>
                    </div>
                    <!-- Icon Text -->
                    <div class="icon-text">
                        <h3 class="mb-2">Fully functional</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>