import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";

import { AppRoutes } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { DemoMaterialModule } from "./demo-material-module";
import { FlexLayoutModule } from "@angular/flex-layout";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CountdownModule } from "ngx-countdown";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { WhatsAppCornerComponent } from "./components/whatsapp-corner/whatsapp-corner.component";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { HomePageComponent } from "./components/homepage/homepage.component";
import { WelcomeHomeComponent } from "./components/welcome-home/welcome-home.component";
import { FullBannerComponent } from "./components/full-banner/full-banner.component";
import { HighlightComponent } from "./components/highlight/highlight.component";
import { CategoryComponent } from "./components/category/category.component";
import { PromotionSliderComponent } from "./components/promotion-slider/promotion-slider.component";
import { SearchResultComponent } from "./components/search-result/search-result.component";
import { PersonalStoreComponent } from "./components/personal-store/personal-store.component";
import { ProductDetailsComponent } from "./components/product-details/product-details.component";
import { CartComponent } from "./components/cart/cart.component";
import { FeatureTabsComponent } from "./components/feature-tabs/feature-tabs.component";
import { ProductRecommendationComponent } from "./components/product-recommendation/product-recommendation.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { HelpComponent } from "./components/help/help.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { CheckoutConfirmationComponent } from "./components/checkout-confirmation/checkout-confirmation.component";
import { PurchaseComponent } from "./components/purchase/purchase.component";
import { ProfileComponent } from "./components/accounts/profile/profile.component";
import { MyProfileComponent } from "./components/accounts/profile/myprofile/myprofile.component";
import { OrderComponent } from "./components/accounts/profile/order/order.component";
import { ShopByCollectionComponent } from "./components/shop-by-collection/shop-by-collection.component";

import { WelcomeOneComponent } from "./components/welcome/welcome-one/welcome-one.component";
import { CounterComponent } from "./components/counter/counter.component";
import { FeatureOneComponent } from "./components/features/feature-one/feature-one.component";
import { ServiceOneComponent } from "./components/services/service-one/service-one.component";
import { DiscoverOneComponent } from "./components/discover/discover-one/discover-one.component";
import { WorkComponent } from "./components/work/work.component";
import { ScreenshotOneComponent } from "./components/screenshots/screenshot-one/screenshot-one.component";
import { PricingOneComponent } from "./components/pricing/pricing-one/pricing-one.component";
import { FaqOneComponent } from "./components/faq/faq-one/faq-one.component";
import { TeamComponent } from "./components/team/team.component";
import { DownloadComponent } from "./components/download/download.component";
import { SubscribeComponent } from "./components/subscribe/subscribe.component";
import { ContactComponent } from "./components/contact/contact.component";
import { FooterOneComponent } from "./components/footer/footer-one/footer-one.component";
import { FooterTwoComponent } from "./components/footer/footer-two/footer-two.component";
import { ScrollupComponent } from "./components/scrollup/scrollup.component";
import { ThemeOneComponent } from "./themes/theme-one/theme-one.component";
import { ThemeTwoComponent } from "./themes/theme-two/theme-two.component";
import { WelcomeTwoComponent } from "./components/welcome/welcome-two/welcome-two.component";
import { FeatureTwoComponent } from "./components/features/feature-two/feature-two.component";
import { DiscoverTwoComponent } from "./components/discover/discover-two/discover-two.component";
import { ServiceTwoComponent } from "./components/services/service-two/service-two.component";
import { ScreenshotTwoComponent } from "./components/screenshots/screenshot-two/screenshot-two.component";
import { ReviewOneComponent } from "./components/reviews/review-one/review-one.component";
import { ReviewTwoComponent } from "./components/reviews/review-two/review-two.component";
import { FaqTwoComponent } from "./components/faq/faq-two/faq-two.component";
import { ThemeThreeComponent } from "./themes/theme-three/theme-three.component";
import { ThemeFourComponent } from "./themes/theme-four/theme-four.component";
import { ThemeFiveComponent } from "./themes/theme-five/theme-five.component";
import { ThemeSixComponent } from "./themes/theme-six/theme-six.component";
import { WelcomeThreeComponent } from "./components/welcome/welcome-three/welcome-three.component";
import { WelcomeFourComponent } from "./components/welcome/welcome-four/welcome-four.component";
import { WelcomeFiveComponent } from "./components/welcome/welcome-five/welcome-five.component";
import { WelcomeSixComponent } from "./components/welcome/welcome-six/welcome-six.component";
import { FeatureThreeComponent } from "./components/features/feature-three/feature-three.component";
import { DiscoverThreeComponent } from "./components/discover/discover-three/discover-three.component";
import { ServiceThreeComponent } from "./components/services/service-three/service-three.component";
import { ReviewThreeComponent } from "./components/reviews/review-three/review-three.component";
import { PricingTwoComponent } from "./components/pricing/pricing-two/pricing-two.component";
import { ServiceFourComponent } from "./components/services/service-four/service-four.component";
import { DiscoverFourComponent } from "./components/discover/discover-four/discover-four.component";
import { FeatureFourComponent } from "./components/features/feature-four/feature-four.component";
import { FeatureFiveComponent } from "./components/features/feature-five/feature-five.component";
import { ServiceFiveComponent } from "./components/services/service-five/service-five.component";
import { DiscoverFiveComponent } from "./components/discover/discover-five/discover-five.component";
import { PricingThreeComponent } from "./components/pricing/pricing-three/pricing-three.component";
import { ServiceSixComponent } from "./components/services/service-six/service-six.component";
import { DiscoverSixComponent } from "./components/discover/discover-six/discover-six.component";
import { BrandComponent } from "./components/brand/brand.component";
import { FeatureSixComponent } from "./components/features/feature-six/feature-six.component";
import { PricingFourComponent } from "./components/pricing/pricing-four/pricing-four.component";
import { BlogTwoColumnComponent } from "./components/blogs/blog-two-column/blog-two-column.component";
import { BlogThreeColumnComponent } from "./components/blogs/blog-three-column/blog-three-column.component";
import { BlogLeftSidebarComponent } from "./components/blogs/blog-left-sidebar/blog-left-sidebar.component";
import { BlogRightSidebarComponent } from "./components/blogs/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsLeftSidebarComponent } from "./components/blogs/blog-details-left-sidebar/blog-details-left-sidebar.component";
import { BlogDetailsRightSidebarComponent } from "./components/blogs/blog-details-right-sidebar/blog-details-right-sidebar.component";
import { PricingComponent } from "./components/inner-pages/pricing/pricing.component";
import { ThankYouComponent } from "./components/inner-pages/thank-you/thank-you.component";
import { ComingSoonComponent } from "./components/inner-pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./components/inner-pages/error/error.component";
import { LoginComponent } from "./components/accounts/login/login.component";
import {
  DialogSignUp,
  SignupComponent,
} from "./components/accounts/signup/signup.component";
import { ResetComponent } from "./components/accounts/reset/reset.component";
import { BreadcrumbPricingComponent } from "./components/breadcrumb/breadcrumb-pricing/breadcrumb-pricing.component";
import { BreadcrumbBlogTwoColumnComponent } from "./components/breadcrumb/breadcrumb-blog-two-column/breadcrumb-blog-two-column.component";
import { BreadcrumbBlogThreeColumnComponent } from "./components/breadcrumb/breadcrumb-blog-three-column/breadcrumb-blog-three-column.component";
import { BreadcrumbBlogLeftSidebarComponent } from "./components/breadcrumb/breadcrumb-blog-left-sidebar/breadcrumb-blog-left-sidebar.component";
import { BreadcrumbBlogRightSidebarComponent } from "./components/breadcrumb/breadcrumb-blog-right-sidebar/breadcrumb-blog-right-sidebar.component";
import { BreadcrumbBlogDetailsLeftSidebarComponent } from "./components/breadcrumb/breadcrumb-blog-details-left-sidebar/breadcrumb-blog-details-left-sidebar.component";
import { BreadcrumbBlogDetailsRightSidebarComponent } from "./components/breadcrumb/breadcrumb-blog-details-right-sidebar/breadcrumb-blog-details-right-sidebar.component";
import { BreadcrumbReviewsComponent } from "./components/breadcrumb/breadcrumb-reviews/breadcrumb-reviews.component";
import { FaqThreeComponent } from "./components/faq/faq-three/faq-three.component";
import { BreadcrumbFaqComponent } from "./components/breadcrumb/breadcrumb-faq/breadcrumb-faq.component";
import { BreadcrumbContactComponent } from "./components/breadcrumb/breadcrumb-contact/breadcrumb-contact.component";
import { ReviewPageComponent } from "./components/inner-pages/review-page/review-page.component";
import { DownloadPageComponent } from "./components/inner-pages/download-page/download-page.component";
import { SubscribePageComponent } from "./components/inner-pages/subscribe-page/subscribe-page.component";
import { FaqPageComponent } from "./components/inner-pages/faq-page/faq-page.component";
import { ContactPageComponent } from "./components/inner-pages/contact-page/contact-page.component";
import { HeaderOneComponent } from "./components/header/header-one/header-one.component";
import { HeaderTwoComponent } from "./components/header/header-two/header-two.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AddPaymentComponent } from "./components/accounts/profile/myprofile/add-payment/add-payment.component";
import { EditAddressComponent } from "./components/accounts/profile/myprofile/edit-address/edit-address.component";
import { DetailComponent } from "./components/accounts/profile/order/detail/detail.component";
import { SelectAddressComponent } from "./components/checkout/select-address/select-address.component";
import {
  AddressComponent,
  AddAddress,
} from "./components/accounts/profile/myprofile/address/address.component";
import { DetailProfileComponent } from "./components/accounts/profile/myprofile/detail-profile/detail-profile.component";
import {
  AddPaymentDialog,
  PaymentComponent,
} from "./components/accounts/profile/myprofile/payment/payment.component";
import { MessageCornerComponent } from "./components/message-corner/message-corner.component";
import { ConfirmProfileComponent } from "./components/accounts/profile/myprofile/detail-profile/confirm-profile/confirm-profile.component";
import {
  ConfirmAddressComponent,
  ConfirmDeleteAddressComponent,
  ConfirmEditAddressComponent,
} from "./components/accounts/profile/myprofile/address/confirm-address/confirm-address.component";
import { ConfirmPaymentComponent } from "./components/accounts/profile/myprofile/payment/confirm-payment/confirm-payment";
import {
  AllBillsComponent,
  AllBillsDialog,
} from "./components/all-bills/all-bills.component";

import { HomepageDemoComponent } from "./components/main-header-demo/homepage-demo.component";
import { FeatureTabsDemoComponent } from "./components/feature-tabs-demo/feature-tabs-demo.component";
import { FullBannerDemoComponent } from "./components/full-banner-demo/full-banner-demo.component";
import { PulsaComponent } from "./components/all-bills/pulsa/pulsa.component";
import { PaketDataComponent } from "./components/all-bills/paket-data/paket-data.component";
import { PlnComponent } from "./components/all-bills/pln/pln.component";
import { GameComponent } from "./components/all-bills/game/game.component";
import { BpjsComponent } from "./components/all-bills/bpjs/bpjs.component";
import { PdamComponent } from "./components/all-bills/pdam/pdam.component";
import { HiburanComponent } from "./components/all-bills/hiburan/hiburan.component";
import { TiketKeretaComponent } from "./components/all-bills/tiket-kereta/tiket-kereta.component";
import { TiketPesawatComponent } from "./components/all-bills/tiket-pesawat/tiket-pesawat.component";
import { SamsatComponent } from "./components/all-bills/samsat/samsat.component";
import { CampaignTemplateComponent } from "./components/campaign-template/campaign-template.component";

// SELLER DECTION //
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { AuthGuard } from "./auth.guard";
import { ApiService } from "./api.service";
import { HttpClientModule } from "@angular/common/http";
import { CategoryProductComponent } from "./components/category-product/category-product.component";
import { ProductComponent } from "./components/personal-store/product/product.component";
import { ReviewComponent } from "./components/personal-store/review/review.component";
import { StoreProfileComponent } from "./components/personal-store/store-profile/store-profile.component";
import { UrlSerializer } from "@angular/router";
import { NumberFormatPipe, VoucherFormatPipe } from "./number.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SelectServiceComponent } from "./components/checkout/select-service/select-service/select-service.component";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { VoucherDetailsComponent } from "./components/product-details/voucher-details/voucher-details.component";
import { SelectVoucherComponent } from "./components/checkout/select-voucher/select-voucher.component";
import { MdePopoverModule } from "@material-extended/mde";
import { StoreComponent } from "./components/store/store.component";
import { DialogConfirmComponent } from "./components/accounts/profile/order/dialog-confirm/dialog-confirm.component";
import { DialogCancelComponent } from "./components/accounts/profile/order/dialog-cancel/dialog-cancel.component";
import { VerifyPhoneOtpComponent } from "./components/accounts/signup/verify-phone-otp/verify-phone-otp.component";
import { ShareModalComponent } from "./components/product-details/share-modal/share-modal.component";
import { ChangePasswordsComponent } from "./components/accounts/profile/myprofile/change-passwords/change-passwords.component";
import { NgxFastMarqueeModule } from "ngx-fast-marquee";
import { BreadcrumbHelpComponent } from './components/breadcrumb/breadcrumb-help/breadcrumb-help.component';
import { ChatComponent } from './components/accounts/profile/myprofile/chat/chat.component';
import { MessageComponent } from './components/accounts/profile/myprofile/chat/message/message.component';
import { RatingAndReviewComponent } from "./components/accounts/profile/order/review/review.component";
import { ShortNumberPipePipe } from './pipes/short-number-pipe.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { ReviewModalComponent } from './components/product-details/review-modal/review-modal.component';
import { FavoriteComponent } from './components/accounts/profile/myprofile/favorite/favorite.component';
import { TransformCityPipe } from './pipes/transform-city.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    WhatsAppCornerComponent,
    MainHeaderComponent,
    HomePageComponent,
    WelcomeHomeComponent,
    FullBannerComponent,
    HighlightComponent,
    CategoryComponent,
    PromotionSliderComponent,
    SearchResultComponent,
    PersonalStoreComponent,
    ProductDetailsComponent,
    CartComponent,
    FeatureTabsComponent,
    ProductRecommendationComponent,
    AboutUsComponent,
    HelpComponent,
    ShopByCollectionComponent,
    CampaignTemplateComponent,

    WelcomeOneComponent,
    CounterComponent,
    FeatureOneComponent,
    ServiceOneComponent,
    DiscoverOneComponent,
    WorkComponent,
    ScreenshotOneComponent,
    PricingOneComponent,
    FaqOneComponent,
    TeamComponent,
    DownloadComponent,
    SubscribeComponent,
    ContactComponent,
    FooterOneComponent,
    FooterTwoComponent,
    ScrollupComponent,
    ThemeOneComponent,
    ThemeTwoComponent,
    WelcomeTwoComponent,
    FeatureTwoComponent,
    DiscoverTwoComponent,
    ServiceTwoComponent,
    ScreenshotTwoComponent,
    ReviewOneComponent,
    ReviewTwoComponent,
    FaqTwoComponent,
    ThemeThreeComponent,
    ThemeFourComponent,
    ThemeFiveComponent,
    ThemeSixComponent,
    WelcomeThreeComponent,
    WelcomeFourComponent,
    WelcomeFiveComponent,
    WelcomeSixComponent,
    FeatureThreeComponent,
    DiscoverThreeComponent,
    ServiceThreeComponent,
    ReviewThreeComponent,
    PricingTwoComponent,
    ServiceFourComponent,
    DiscoverFourComponent,
    FeatureFourComponent,
    FeatureFiveComponent,
    ServiceFiveComponent,
    DiscoverFiveComponent,
    PricingThreeComponent,
    ServiceSixComponent,
    DiscoverSixComponent,
    BrandComponent,
    FeatureSixComponent,
    PricingFourComponent,
    BlogTwoColumnComponent,
    BlogThreeColumnComponent,
    BlogLeftSidebarComponent,
    BlogRightSidebarComponent,
    BlogDetailsLeftSidebarComponent,
    BlogDetailsRightSidebarComponent,
    PricingComponent,
    ThankYouComponent,
    ComingSoonComponent,
    ErrorComponent,
    LoginComponent,
    SignupComponent,
    ResetComponent,
    BreadcrumbPricingComponent,
    BreadcrumbBlogTwoColumnComponent,
    BreadcrumbBlogThreeColumnComponent,
    BreadcrumbBlogLeftSidebarComponent,
    BreadcrumbBlogRightSidebarComponent,
    BreadcrumbBlogDetailsLeftSidebarComponent,
    BreadcrumbBlogDetailsRightSidebarComponent,
    BreadcrumbReviewsComponent,
    FaqThreeComponent,
    BreadcrumbFaqComponent,
    BreadcrumbContactComponent,
    ReviewPageComponent,
    DownloadPageComponent,
    SubscribePageComponent,
    FaqPageComponent,
    ContactPageComponent,
    HeaderOneComponent,
    HeaderTwoComponent,
    CheckoutComponent,
    CheckoutConfirmationComponent,
    PurchaseComponent,
    MyProfileComponent,
    ProfileComponent,
    OrderComponent,
    AddPaymentComponent,
    EditAddressComponent,
    DetailComponent,
    SelectAddressComponent,
    AddressComponent,
    DetailProfileComponent,
    PaymentComponent,
    MessageCornerComponent,
    AddAddress,
    AddPaymentDialog,
    ConfirmProfileComponent,
    ConfirmAddressComponent,
    ConfirmEditAddressComponent,
    ConfirmDeleteAddressComponent,
    ConfirmPaymentComponent,
    AllBillsComponent,
    PulsaComponent,
    PaketDataComponent,
    PlnComponent,
    GameComponent,
    BpjsComponent,
    PdamComponent,
    HiburanComponent,
    TiketKeretaComponent,
    TiketPesawatComponent,
    SamsatComponent,
    HomepageDemoComponent,
    FeatureTabsDemoComponent,
    FullBannerDemoComponent,
    AllBillsDialog,
    DialogSignUp,
    CategoryProductComponent,
    ProductComponent,
    ReviewComponent,
    StoreProfileComponent,
    SelectServiceComponent,
    VoucherDetailsComponent,
    SelectVoucherComponent,
    StoreComponent,
    DialogConfirmComponent,
    DialogCancelComponent,
    VerifyPhoneOtpComponent,
    ShareModalComponent,
    ChangePasswordsComponent,
    BreadcrumbHelpComponent,
    ChatComponent,
    MessageComponent,
    RatingAndReviewComponent,
    ShortNumberPipePipe,
    TimeAgoPipe,
    ReviewModalComponent,
    FavoriteComponent,
    TransformCityPipe

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    HttpClientModule,
    FontAwesomeModule,
    MdePopoverModule,
    NgxFastMarqueeModule,

    // SELLER //

    PerfectScrollbarModule,

    // SELLER //

    InfiniteScrollModule,
    CountdownModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: "legacy" }),
  ],
  providers: [
    AuthGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    ApiService,
    DatePipe,
    NumberFormatPipe,
    VoucherFormatPipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmProfileComponent,
    ConfirmAddressComponent,
    AddAddress,
    AddPaymentDialog,
    ConfirmPaymentComponent,
    AddPaymentComponent,
    EditAddressComponent,
    DetailComponent,
    SelectAddressComponent,
    AllBillsDialog,
    LoginComponent,
    RatingAndReviewComponent
  ],
})
export class AppModule { }
