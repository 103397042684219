import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-change-passwords",
  templateUrl: "./change-passwords.component.html",
  styleUrls: ["./change-passwords.component.css"],
})
export class ChangePasswordsComponent implements OnInit {
  translateArr = [localStorage.getItem("translate")];

  hide = true;
  public form: FormGroup;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private routes: Router,
    private fc: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fc.group(
      {
        old_password: [null, Validators.compose([Validators.required])],
        new_password: [null, Validators.compose([Validators.required])],
        confirm_password: [null, Validators.compose([Validators.required])],
      },
      {
        validator: this.checkIfMatchingPasswords(
          "new_password",
          "confirm_password"
        ),
      }
    );
    // console.log(this.form.valid)
  }

  checkIfMatchingPasswords(new_password: string, confirm_password: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[new_password],
        passwordConfirmationInput = group.controls[confirm_password];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  resetPassword() {
    Swal.fire({
      title: "Apakah anda yakin ingin mengganti password?",
      icon: "warning",
      text: "Proses tidak dapat dikembalikan!",
      showCancelButton: true,
      confirmButtonText: "Ganti",
      cancelButtonText: `Batalkan`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService
          .ChangeUserPassword(
            this.form.value.old_password,
            this.form.value.new_password
          )
          .subscribe(
            () => {
              this.dialog.open(DialogAlertPass, {
                width: "300px",
              });
              this.form.reset();
              this.routes.navigate(["/user/change-password"]);
            },
            (err) => {
              Swal.fire({
                title: "Ada kesalahan",
                text: err.error.message,
                icon: "warning",
                confirmButtonText: "OK",
              });
            }
          );
      }
    });
    // if (window.confirm("Apakah anda yakin ingin mengubah password?")) {
    //   this.apiService
    //     .ChangeUserPassword(
    //       this.form.value.old_password,
    //       this.form.value.new_password
    //     )
    //     .subscribe(
    //       () => {
    //         this.dialog.open(DialogAlertPass, {
    //           width: "300px",
    //         });
    //         this.form.reset();
    //         this.routes.navigate(["/user/change-password"]);
    //       },
    //       (err) => {
    //         Swal.fire({
    //           title: "Ada kesalahan",
    //           text: err.error.message,
    //           icon: "warning",
    //           confirmButtonText: "OK",
    //         });
    //       }
    //     );
    // }
  }
}

@Component({
  selector: "dialog-alert",
  templateUrl: "dialog-alert.html",
  styleUrls: ["./change-passwords.component.css"],
})
export class DialogAlertPass {}
