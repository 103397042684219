import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-myprofile",
  templateUrl: "./myprofile.component.html",
  styleUrls: ["./myprofile.component.css"],
})
export class MyProfileComponent implements OnInit {
  title = "angular-material-tab-router";
  navLinks: any[];
  activeLinkIndex = -1;

  constructor(private router: Router) {
    this.navLinks = [
      {
        label: "Profil",
        link: "detail-profile",
        index: 0,
      },
      // {
      //   label: "Ubah Password",
      //   link: "change-password",
      //   index: 1,
      // },
      {
        label: "Alamat",
        link: "address",
        index: 1,
      },
      // {
      //   label: "Chat",
      //   link: "chats",
      //   index: 2,
      // },
      // {
      //     label: 'Pembayaran',
      //     link: 'payment',
      //     index: 2
      // },
    ];
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(
        this.navLinks.find((tab) => tab.link === "." + this.router.url)
      );
    });
  }
}
