<section id="features" class="section features-area overflow-hidden mt-5 ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Why Small Business Owner's Loves sApp</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="features-slider-wrapper overflow-hidden">
                    <!-- Work Slider -->
                    <ul class="features-slider owl-carousel">
                        <li class="slide-item">
                            <img src="assets/img/screenshot_1.jpg" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_2.jpg" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_3.jpg" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_4.jpg" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 pt-4 pt-md-0">
                <ul class="features-item">
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-1 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.2s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_1.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Fully functional</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-2 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_2.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Live Chat</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-3 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.6s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_3.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Secure Data</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Image Box -->
                        <div class="image-box media icon-4 px-1 py-3 py-md-4 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.8s">
                            <!-- Featured Image -->
                            <div class="featured-img mr-3">
                                <img class="avatar-sm" src="assets/img/featured_image_4.png" alt="">
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Easy to Customize</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>