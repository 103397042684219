<section
  class="section breadcrumb-area bg-overlay d-flex align-items-center"
  style="background-color: #2563eb"
>
  <div class="container position-relative">
    <div class="row">
      <div class="col-12">
        <!-- Breadcrumb Content -->
        <div class="breadcrumb-content1">
          <h3 class="text-white" style="text-shadow: 1px 1px 12px #fff">
            PT. SURYA MANDIRI MUTUTAMA - Helmet and Corporate Essentials
            Manufacture
          </h3>
        </div>
      </div>
    </div>
  </div>
</section>
