<div class="blog">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-whatsapp-corner></app-whatsapp-corner>
        <!-- <app-message-corner></app-message-corner> -->
        <app-main-header></app-main-header>

        <section class="section breadcrumb d-flex align-items-center">
            <img class="image-breadcrumb" src="assets/img/januari.png">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section counter-area ptb_30">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <!-- Section Heading -->
                        <div class="section-heading text-center">
                            <h2 class="mb-5">Retro Fashion</h2>
                            <p class="about-desc mt-5 text-gray ">Jangan Lewatkan diskon besar-besaran untuk produk retro di toko terpilh. Pilih produk retro favoritmu untuk tampil lebih menarik. Retro fashion memberikan kesan vintage yang tetap kekinian untuk melengkapi OOTD mu.
                                <p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section service-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex flex-row promo-slide">
                        <div class="col-4 promo-banner">
                            <img class="img-fluid" src="assets/img/februari.png" alt="">
                        </div>
                        <div class="col-4 promo-banner">
                            <img class="img-fluid" src="assets/img/februari.png" alt="">
                        </div>
                        <div class="col-4 promo-banner">
                            <img class="img-fluid" src="assets/img/februari.png" alt="">
                        </div>
                        <div class="col-4 promo-banner">
                            <img class="img-fluid" src="assets/img/februari.png" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section id="review" class="review-area ptb_30">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class=" trending text-center py-3">Best Deal For Retro fashion</h3>
                    </div>
                    <div class="highligt d-none d-md-block col-md-4 col-lg-3 col-xl-2 mb-3 pl-1 pr-1" *ngFor="let highlight of highlight">
                        <a href="/product">
                            <mat-card class="content h-100" style="overflow: hidden;">
                                <img mat-card-image class="product-card-image" src="{{highlight.image}}" alt="product image">
                                <mat-card-content>
                                    <div>
                                        <p class="over">{{highlight.productname}}</p>
                                    </div>
                                    <div>
                                        <p class="bold" style="margin-bottom: 0px; font-size: 13px;">{{highlight.price}}</p>
                                    </div>
                                </mat-card-content>
                                <mat-card-footer align="end" class="d-flex align-items-end flex-column">
                                    <div class="mt-auto">
                                        <p class="desc"><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i></p>
                                    </div>
                                    <div class="mt-auto">
                                        <p class="desc"><i class=" marker fas fa-map-marker-alt"></i> {{highlight.location}}</p>
                                    </div>
                                </mat-card-footer>
                            </mat-card>
                        </a>
                    </div>
                </div>

                <!-- RESPONSIVE -->
                <mat-card class="d-block .d-sm-none d-md-none mb-3" *ngFor="let highlight of highlight">
                    <div class="col-12">
                        <div class="row d-flex flex-wrap">
                            <div class="col-4 col-md-2 pl-0 pr-0">
                                <img class="align-self-center thumbnail-cart" src="{{highlight.image}}" alt="">
                            </div>
                            <div class="col-8 col-md-6">
                                <p class="over">{{highlight.productname}}</p>
                                <p class="bold">{{highlight.price}}</p>
                                <p class="desc text-right"><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i><i class=" marker fas fa-star"></i></p>
                                <p class="desc text-right"><i class=" marker fas fa-map-marker-alt"></i> {{highlight.location}}</p>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </section>
        <app-footer-one></app-footer-one>
    </div>
</div>