import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "src/app/api.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NumberFormatPipe, VoucherFormatPipe } from "../../number.pipe";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { LoginComponent } from "../accounts/login/login.component";
import { clearLocalStorage } from "src/app/utility.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { of } from "rxjs";
import { Meta } from "@angular/platform-browser";
import {
  catchError,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import { VoucherDetailsComponent } from "./voucher-details/voucher-details.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogAlert } from "../accounts/profile/myprofile/detail-profile/detail-profile.component";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";
import { ShareModalComponent } from "./share-modal/share-modal.component";
import { listReview, review, reviewData } from "../models/review-and-rating/review-and-rating.interface";
import { ReviewModalComponent } from "./review-modal/review-modal.component";
import { ProductWishlist, UpdateWishlist } from "../models/wishlist/wishlist";

declare var zoomOnHover: any;

interface variantDetails {
  product_id: number;
  product_name: string;
  variant_id: number;
  variant_name: string;
  quantity: number;
  variant_price: number;
  product_image: any;
  subtotal: number;
  store_address_id: number;
  voucher_id: number;
  voucher_code: string;
  voucher_name: string;
}

interface productDetails {
  product_id: number;
  name: string;
  store: any;
  brand_name: string;
  description: string;
  product_variants: any;
  photo_url: any;
  category_id: number;
  width: number;
  length: number;
  avg_rating: string;
  category_name: string;
  min_order: number;
  total_sold: number;
  height: number;
  total_variant: number;
  weight: number;
  vouchers: any;
  osas_log_id: number;
  total_voucher: number;
  sold: number;
  total_product: number;
}

interface checkFeeParam {
  originId: number;
  destinationId: number;
  weight: number;
  dimension: {
    length: number;
    height: number;
    width: number;
  };
}

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
  providers: [NumberFormatPipe, VoucherFormatPipe, ApiService],
})
export class ProductDetailsComponent implements OnInit {
  productDetails: productDetails;

  variantDetails: variantDetails = {
    product_id: 0,
    product_name: "",
    variant_id: 0,
    variant_name: "",
    quantity: 0,
    variant_price: 0,
    product_image: "",
    subtotal: 0,
    store_address_id: 0,
    voucher_id: 0,
    voucher_code: "",
    voucher_name: "",
  };

  checkFeeParam: checkFeeParam = {
    originId: 0,
    destinationId: 0,
    weight: 1,
    dimension: {
      length: 1,
      height: 1,
      width: 1,
    },
  };


  discussions: any;
  timeOnline: any;
  selectedPhoto: string;

  params: ParamMap;
  product_id: any;
  // store_id: any
  radioSelected: any;
  selectRadio: boolean = false;
  selectDest: boolean = false;
  message: string;
  reason: string;
  imageUrl: string;
  // store: any
  loggedin: string = "false";
  isLoading: boolean = false;
  productNotFound: boolean = false;

  productPrice: number | null = null;
  productStock: number | null = null;
  productCity: string;
  variantId: number | null = null;
  inputnumber: number;
  minOrder: number;
  form: FormGroup;
  destinationSearchData: any = [];
  destinationCityName: string;
  services: any[];
  minServiceFee: number;
  maxServiceFee: number;

  // Global Variable for store

  store_name: string;
  store_image: string;
  store_city: string;
  store_id: any;

  // public autoCorrect = true
  // public min = 1
  // public max = 10

  reviewPageIndex: number = 0;
  reviews: review;
  reviewLimitSize: number = 20;
  reviewTotalPageSize: any[] = [];
  reviewImageIsEnabled: boolean = false
  reviewVideoIsEnabled: boolean = false
  listReviewSort = [
    {
      name: "Terbaru",
      value: "newer"
    },
    {
      name: "Rating Tertinggi",
      value: "rating desc"
    },
    {
      name: "Rating Terendah",
      value: "rating asc"
    }
  ]
  sort = "newer"

  reviewForm: FormGroup

  filter = {
    video: false,
    image: false,
    rating: [
      {
        name: 5,
        value: false,
        key: 'rating_5'
      },
      {
        name: 4,
        value: false,
        key: 'rating_4'
      },
      {
        name: 3,
        value: false,
        key: 'rating_3'
      },
      {
        name: 2,
        value: false,
        key: 'rating_2'
      },
      {
        name: 1,
        value: false,
        key: 'rating_1'
      },
    ]
  }

  favorite = {
    id: null,
    count: 0
  }

  // TODO voucher, image, user image for review, fix created_at format for review + discussion, ongkos kirim
  // TODO last online, selected product images, quantity min max follows chosen

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private voucherFormat: VoucherFormatPipe,
    private datepipe: DatePipe,
    public dialog: MatDialog,
    private routes: Router,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private meta: Meta
  ) {

    // this.reviewForm = this.fb.group({
    //   sort : ['newer'],
    //   filter: this.fb.group({
    //     video:
    //   })
    // })
    this.params = this.route.snapshot.queryParamMap;
    this.product_id = this.params.get("product_id");
    this.meta.updateTag({ property: "og:title", content: "Onmarket" });
    this.fetchVouchers();
    this.fetchProductDetails();
    this.fetchReviews(this.product_id, this.reviewPageIndex);
    this.fetchDiscussions();

    // this.service.getStoreId().subscribe(data => {
    //   this.store = data
    //   console.log("ProductDetailsComponent-getStoreId")
    //   console.log(data)
    // }, e => {
    //   this.store = null
    //   console.log(e)
    // })
  }

  getTransformedStoreDomain(): string {
    return this.productDetails.store.store_domain.replace(
      "store-profile",
      "store-product"
    );
  }

  fetchProductDetails() {
    // this.isLoading = true;
    // console.log(this.loggedin)
    // console.log(this.store)
    this.service.productDetail(this.product_id).subscribe(
      (data: any) => {
        console.log("Data produk detail: ", data);
        this.inputnumber = data.min_order;
        this.minOrder = data.min_order;
        this.productDetails = data;
        this.imageUrl = data.store.image_url;
        this.store_name = data.store.name;
        this.store_image = data.store.image_url;
        this.store_city = data.store.city;
        this.selectedPhoto = data.photo_url[0];
        this.store_id = data.store.store_id;
        this.fetchOnline();
        this.meta.updateTag({
          property: "og:image",
          content: this.selectedPhoto,
        });
        for (let i = 0; i < data.product_variants.length; i++) {
          // if (data.product_variants[i].variant_stock !== 0) {
          if (!this.selectRadio) {
            this.radioSelected =
              this.productDetails.product_variants[i].variant_id;
            this.variantId = this.productDetails.product_variants[i].variant_id;
            this.productStock =
              this.productDetails.product_variants[i].variant_stock;
            this.productPrice =
              this.productDetails.product_variants[i].variant_price;
            break;
          }

          if (!data.height) {
            this.checkFeeParam = {
              originId: this.productDetails.store.osas_log_id,
              destinationId: null,
              weight: this.productDetails.weight,
              dimension: {
                length: this.productDetails.length,
                width: this.productDetails.width,
                height: this.productDetails.height,
              },
            };
          } else {
            this.checkFeeParam = {
              originId: this.productDetails.store.osas_log_id,
              destinationId: null,
              weight: this.productDetails.weight,
              dimension: {
                length: this.productDetails.length,
                width: this.productDetails.width,
                height: this.productDetails.height,
              },
            };
          }
        }

        if (this.checkFeeParam.destinationId == 0) {
          this.destinationCityName = data.store.city;
          this.checkFeeParam = {
            originId: this.productDetails.store.osas_log_id,
            destinationId: data.store.osas_log_id,
            weight: this.productDetails.weight,
            dimension: {
              length: this.productDetails.length,
              width: this.productDetails.width,
              height: this.productDetails.height,
            },
          };
          this.pickService(this.checkFeeParam);
        }
        console.log("form checkfee", this.checkFeeParam);
        // console.log(data)
        // console.log(this.productDetails.vouchers)
        this.isLoading = false;
      },
      (e) => {
        console.log(e);
        this.isLoading = false;
        if (e.error.message === "Product not Found") {
          Swal.fire({
            title: "Produk tidak ditemukan",
            text: "Produk yang anda cari tidak ditemukan",
            icon: "error",
            confirmButtonText: "Kembali",
            confirmButtonColor: "#ff0000",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.goBack();
            }
          });
        }
      }
    );
  }

  goBack(): void {
    window.history.back();
  }

  hoverZoom(e: any) {
    new zoomOnHover(e);
  }

  useDefaultProfileImg() {
    this.imageUrl = "assets/img/store.png";
  }

  selectThumbnail(i: string) {
    // console.log(i)
    this.selectedPhoto = i;
  }

  shareProduct() {
    const dialogRef = this.dialog.open(ShareModalComponent, {
      data: {
        url: this.service.BASEURL + "product?product_id=" + this.product_id,
        product: this.productDetails,
        selected: this.radioSelected,
      },
    });
    // this.clipboard.copy(
    //   this.service.BASEURL + "product?product_id=" + this.product_id
    // );
    // this.openSnackBar("Tautan berhasil di salin", "Ok");
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      id: "LoginDialog",
    });
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.form = this.fb.group({
      dest: [null, Validators.compose([Validators.required])],
    });

    this.fetchVouchers();
    this.fetchProductDetails();
    this.getWishlist()

    //Test if user status still loggedin
    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.service.verify(localStorage.getItem("jwt")).subscribe(
        (r) => {
          // console.log("RESPONS VERIFY", r);
          this.loggedin = "true";
          this.service.userMainAddress().subscribe((mainAddress: any) => {
            // console.log("main address:", mainAddress);
            // console.log("Address id:", mainAddress[0].address_id);
            this.service
              .userAddressDetail(mainAddress[0].address_id)
              .subscribe((addressDetail: any) => {
                console.log("address detail", addressDetail);
                this.checkFeeParam.destinationId = addressDetail.osas_log_id;
                this.destinationCityName = addressDetail.city;
                this.isLoading = false;
              });
          });
          // console.log(this.loggedin)
        },
        (e) => {
          console.log(e);
          clearLocalStorage();
        }
      );
    } else {
      clearLocalStorage();
    }
    // this.getLocation()

    this.fetchReviews(this.product_id, this.reviewPageIndex);
    this.fetchDiscussions();

    // $('#carousel').attr("style", "display: none").then(this.isLoading = false);
  }

  // getLocation(): void {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const longitude = position.coords.longitude;
  //       const latitude = position.coords.latitude;
  //     });
  //   } else {
  //     console.log("No support for geolocation")
  //   }
  // }

  private destination_serv(value: string): any {
    return this.service.destinationSearch(value).pipe(
      map((res) => {
        for (var i = res.length; i--;) {
          if (res[i].urban_name.slice(0, 9) == "KECAMATAN") {
            res.splice(i, 1);
          }
        }
        return res;
      }),
      catchError((err) => of([]))
    );
  }

  displayFnDest(consignee?: any): string | undefined {
    return consignee
      ? consignee.urban_name +
      ", " +
      consignee.sub_district_name +
      ", " +
      consignee.city_name +
      ", Prov. " +
      consignee.province_name +
      ", " +
      consignee.postal_code
      : undefined;
  }

  dateTransform(date: string | number | Date) {
    return this.datepipe.transform(date, "d MMMM yyyy HH:mm");
  }

  fetchOnline() {
    console.log("store id: ", this.store_id);
    this.service.storeInfo(this.store_id).subscribe((res: any) => {
      const timeRes = res.last_online;
      this.productCity = res.city;
      const currentTime = dayjs();
      this.timeOnline = currentTime.diff(timeRes, "minute");
    });
  }

  variantSwitch(event: { target: { id: number } }) {
    const productVariant = this.productDetails.product_variants;
    const index = productVariant.findIndex((item: { variant_id: any }) => {
      return item.variant_id == event.target.id;
    });
    this.radioSelected = event.target.id;
    this.variantId = event.target.id;
    this.productStock = productVariant[index].variant_stock;
    this.productPrice = productVariant[index].variant_price;
    this.inputnumber;
    this.selectRadio = true;
    console.log("Variant Switch");
    console.log(event.target.id);
  }

  voucherDetails: any;

  fetchVouchers() {
    // this.isLoading = true
    this.service.voucherList().subscribe(
      (data) => {
        // console.log(data)
        this.voucherDetails = data;
        // this.isLoading = false
      },
      (e) => {
        console.log(e);
        // this.isLoading = false
      }
    );
  }

  formatLastOnline() {
    if (this.timeOnline === 0) {
      return "Sedang aktif";
    } else if (this.timeOnline < 60) {
      return "Aktif " + this.timeOnline + " menit yang lalu";
    } else if (this.timeOnline < 1440) {
      return "Aktif " + Math.floor(this.timeOnline / 60) + " jam yang lalu";
    } else if (this.timeOnline < 10080) {
      return "Aktif " + Math.floor(this.timeOnline / 1440) + " hari yang lalu";
    } else if (this.timeOnline < 40320) {
      return (
        "Aktif " + Math.floor(this.timeOnline / 10080) + " minggu yang lalu"
      );
    } else if (this.timeOnline < 483840) {
      return (
        "Aktif " + Math.floor(this.timeOnline / 40320) + " bulan yang lalu"
      );
    } else {
      return (
        "Aktif " + Math.floor(this.timeOnline / 483840) + " tahun yang lalu"
      );
    }
  }

  voucherDetail(e: any) {
    // console.log(e)

    var detailData = e;

    for (let i = 0; i < this.productDetails.product_variants.length; i++) {
      if (
        this.productDetails.product_variants[i].variant_id == this.variantId
      ) {
        this.variantDetails.subtotal =
          this.productDetails.product_variants[i].variant_price *
          this.inputnumber;
      }
    }

    let dialogRef = this.dialog.open(VoucherDetailsComponent, {
      width: "950px",
      height: "470px",
      data: {
        voucher: detailData,
        currentPrice: this.variantDetails.subtotal,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((voucher) => {
      if (voucher) {
        this.variantDetails.voucher_id = voucher.voucher_id;
        this.variantDetails.voucher_code = voucher.code;
        this.variantDetails.voucher_name = voucher.title;
        this.openSnackBar("Voucher berhasil digunakan", "OK");
      }
      this.fetchVouchers();
    });
  }

  fetchReviews(productId: number, pageIndex: number = 0) {
    const payload: listReview = {
      page: pageIndex,
      limit: this.reviewLimitSize,
      sort: this.sort,
      filters: {
        video: this.filter.video,
        image: this.filter.image

      }
    }

    const rating = this.filter.rating.filter(item => item.value === true).map(item => item.name)
    if (rating.length > 0) {
      payload.filters.rating = rating
    }

    this.service.listReview(productId, payload).subscribe((r: review) => {
      this.reviews = r;
      this.reviewTotalPageSize = Array(Math.ceil(r.total / 20));
      this.reviewImageIsEnabled = r.data.map(item => item.image.length > 0).length > 0
      this.reviewVideoIsEnabled = r.data.map(item => item.video).length > 0
      console.log(r)
    },
      err => {
        console.error(err.error.message)
      })

  }

  pickDestination() {
    this.selectDest = true;
    this.destinationSearchData = this.form.get("dest")?.valueChanges.pipe(
      // debounceTime(250),
      distinctUntilChanged(),
      startWith(""),
      switchMap((value) => this.destination_serv(value))
    );
  }

  pickService(checkFeeParam: any) {
    console.log(checkFeeParam);
    this.service.checkDeliveryFee(checkFeeParam).subscribe((res: any) => {
      console.log(res);
      this.services = res.services;
      this.minServiceFee = res.services[0].total;
      this.maxServiceFee = res.services[0].total;
      for (let i = 0; i < res.services.length; i++) {
        if (this.minServiceFee > res.services[i].total) {
          this.minServiceFee = res.services[i].total;
        }

        if (this.maxServiceFee < res.services[i].total) {
          this.maxServiceFee = res.services[i].total;
        }
      }
    });
  }

  onDestChange(option: any) {
    // console.log(option)
    this.checkFeeParam = {
      originId: this.productDetails.store.osas_log_id,
      destinationId: option.id,
      weight: this.checkFeeParam.weight,
      dimension: {
        length: this.checkFeeParam.dimension.length,
        width: this.checkFeeParam.dimension.width,
        height: this.checkFeeParam.dimension.height,
      },
    };
    this.destinationCityName = option.city_name;

    this.pickService(this.checkFeeParam);
  }

  fetchDiscussions() {
    this.service
      .discussionList(this.product_id, this.reviewPageIndex)
      .subscribe(
        (data) => {
          this.discussions = data;
          // console.log("ProductDetailsComponent-Discussion")
          // console.log(data)
        },
        (e) => {
          console.log(e);
        }
      );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  voucherTransform(value: number) {
    return this.voucherFormat.transform(value);
  }

  arrowPagination(arg: string, number: number = 0) {
    if (arg === "previous") {
      this.reviewPageIndex = this.reviewPageIndex - 1;
    } else if (arg === "next") {
      this.reviewPageIndex = this.reviewPageIndex + 1;
    }
    this.fetchReviews(this.product_id, this.reviewPageIndex);
  }

  numberPagination(index: number = 0) {
    this.reviewPageIndex = index;
    this.fetchReviews(this.product_id, this.reviewPageIndex);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  plus() {
    // console.log(this.radioSelected)
    // console.log(this.variantId)
    if (this.inputnumber < this.productStock) {
      this.inputnumber = this.inputnumber + 1;
    }

    // this.fetchProductDetails();
    // this.fetchReviews(this.product_id, this.reviewPageIndex);
    // this.fetchDiscussions();
  }
  minus() {
    // console.log(this.radioSelected)
    // console.log(this.variantId)
    if (this.inputnumber > this.minOrder) {
      this.inputnumber = this.inputnumber - 1;
    }

    // this.fetchProductDetails();
    // this.fetchReviews(this.product_id, this.reviewPageIndex);
    // this.fetchDiscussions();
  }

  onKeyUp() {
    this.inputnumber = Math.max(
      0,
      Math.min(this.productStock, this.inputnumber)
    );

    // this.fetchProductDetails();
    this.fetchReviews(this.product_id, this.reviewPageIndex);
    this.fetchDiscussions();
  }

  addToCart() {
    // Swal.fire({
    //   title: "Oops...",
    //   text: "Fitur ini masih dalam tahap development. Mohon tunggu info lebih lanjut",
    //   icon: "info",
    //   confirmButtonText: "OK",
    // });
    const data = {
      store_id: Number(this.productDetails.store.store_id),
      product_id: Number(this.product_id),
      variant_id: Number(this.variantId),
      quantity: this.inputnumber,
    };
    console.log(data);

    this.service.addCart(data).subscribe(
      (res) => {
        this.service.isUpdateCart.next(true);
        this.openSnackBar(res.message, "Ok");
        this.fetchProductDetails();
        this.fetchReviews(this.product_id, this.reviewPageIndex);
        this.fetchDiscussions();
      },
      (e: any) => {
        this.message = e.error.message;
        this.reason = e.error.reason;
        console.log(e);
        // console.log(e.error.message)
        // this.openSnackBar('Tidak bisa membeli produk sendiri', 'Ok')
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: this.message + ". Karena " + this.reason,
          },
        });
        // this.fetchProductDetails();
        // this.fetchReviews(this.product_id, this.reviewPageIndex);
        // this.fetchDiscussions();
      }
    );
    // let currentUrl = this.routes.url;
    // this.routes.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.routes.onSameUrlNavigation = 'reload';
    // this.routes.navigate([currentUrl]);
    // console.log(currentUrl)
  }

  buyNow() {
    this.variantDetails.product_name = this.productDetails.name;
    this.variantDetails.variant_id = this.variantId;
    this.variantDetails.product_id = this.productDetails.product_id;

    for (let i = 0; i < this.productDetails.product_variants.length; i++) {
      if (
        this.productDetails.product_variants[i].variant_id == this.variantId
      ) {
        this.variantDetails.variant_name =
          this.productDetails.product_variants[i].variant_name;
        this.variantDetails.variant_price =
          this.productDetails.product_variants[i].variant_price;
        this.variantDetails.subtotal =
          this.productDetails.product_variants[i].variant_price *
          this.inputnumber;
      }
    }

    this.variantDetails.quantity = this.inputnumber;
    this.variantDetails.product_image = this.productDetails.photo_url[0];

    const transformedData = [
      {
        cart_detect: true,
        store_id: Number(this.productDetails.store.store_id),
        store_name: this.productDetails.store.name,
        products: [
          {
            product_id: Number(this.productDetails.product_id),
            product_name: this.productDetails.name,
            variant_id: Number(this.variantDetails.variant_id),
            variant_name: this.variantDetails.variant_name,
            variant_price: this.variantDetails.variant_price,
            variant_stock: this.productDetails.product_variants.find(
              (variant: { variant_id: number }) =>
                variant.variant_id == this.variantId
            ).variant_stock,
            subtotal: this.variantDetails.subtotal,
            quantity: this.variantDetails.quantity,
            product_image: this.variantDetails.product_image,
          },
        ],
      },
    ];
    this.routes.navigate(["/checkout"], {
      queryParams: {
        orderInfo: btoa(JSON.stringify(transformedData)),
      },
    });
  }

  handleChat() {
    // history.pushState({ storeId: this.store_id, product: this.service.BASEURL + "product?product_id=" + this.product_id }, '', '/user/chats')
    // this.routes.navigate(['/user/chats'], { queryParams:  })
    const queryParams = { d: btoa(btoa(JSON.stringify({ storeId: this.store_id }))) }

    for (let i = 0; i < this.productDetails.product_variants.length; i++) {
      if (
        this.productDetails.product_variants[i].variant_id == this.variantId
      ) {
        this.variantDetails.variant_name =
          this.productDetails.product_variants[i].variant_name;
        this.variantDetails.variant_price =
          this.productDetails.product_variants[i].variant_price;
        this.variantDetails.subtotal =
          this.productDetails.product_variants[i].variant_price *
          this.inputnumber;
      }
    }
    const productDetail = {
      storeId: this.store_id,
      product: this.service.BASEURL + "product?product_id=" + this.product_id,
      productId: this.product_id,
      variantId: this.variantId,
      productName: this.productDetails.name,
      variantName: this.variantDetails.variant_name,
      price: this.variantDetails.variant_price,
      productImage: this.productDetails.photo_url[0]
    }
    this.routes.navigate(['/user/chats'], { state: productDetail, queryParams })

  }

  onChangeRating() {
    console.log(this.filter)
    this.fetchReviews(this.product_id)
  }

  userProfileImg(username) {
    return this.service.API_USERSTATE_SERVER + `get-profile-img/${username}`
  }

  useDefaultuserProfileImg() {
    return "assets/img/user-avatar.png";
  }

  openReviewDialog(data: reviewData, selectedImage: string) {
    const modalRef = this.dialog.open(ReviewModalComponent, {
      width: "780px",
      data: {
        review: data,
        selectedImage: selectedImage
      }
    })
  }

  getWishlist() {
    this.service.getWishlist(this.product_id).subscribe((r: ProductWishlist) => {
      console.log(r)
      this.favorite.id = r.wishlist_id
      this.favorite.count = r.wishlist_count
    })
  }

  updateFavorite(action: string) {
    let payload: UpdateWishlist = {
      action: action,
      product_id: Number(this.product_id),
      variant_id: Number(this.variantId)
    }

    if (action === 'Delete') payload.wishlist_id = this.favorite.id

    this.service.updateWishlist(payload).subscribe(r => {
      this.getWishlist()
    }, err => { this.getWishlist() })
  }



}
