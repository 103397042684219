<section id="pricing" class="section price-plan-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <span class="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                        <i class="far fa-lightbulb text-primary mr-1"></i>
                        <span class="text-primary">Flexible</span>
                        Pricing
                    </span>
                    <h2>Unlock Full Power Of sApp</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-lg-8">
                <div class="row price-plan-wrapper">
                    <div class="col-12 col-md-6">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan text-center p-5 wow fadeInLeft" data-aos-duration="2s" data-wow-delay="0.4s">
                            <!-- Plan Thumb -->
                            <div class="plan-thumb">
                                <img class="avatar-lg" src="assets/img/price_basic.png" alt="">
                            </div>
                            <!-- Plan Title -->
                            <div class="plan-title my-2 my-sm-3">
                                <h3 class="text-uppercase">Basic</h3>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <h1 class="color-primary"><small class="fw-7">$</small>49</h1>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="border-top py-3">5GB Linux Web Space</li>
                                    <li class="border-top py-3">5 MySQL Databases</li>
                                    <li class="border-top py-3">24/7 Tech Support</li>
                                    <li class="border-top border-bottom py-3">Daily Backups</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="#" class="btn mt-4">Sign Up</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                        <!-- Single Price Plan -->
                        <div class="single-price-plan text-center p-5 wow fadeInRight" data-aos-duration="2s" data-wow-delay="0.4s">
                            <!-- Plan Thumb -->
                            <div class="plan-thumb">
                                <img class="avatar-lg" src="assets/img/price_premium.png" alt="">
                            </div>
                            <!-- Plan Title -->
                            <div class="plan-title my-2 my-sm-3">
                                <h3 class="text-uppercase">Pro</h3>
                            </div>
                            <!-- Plan Price -->
                            <div class="plan-price pb-2 pb-sm-3">
                                <h1 class="color-primary"><small class="fw-7">$</small>129</h1>
                            </div>
                            <!-- Plan Description -->
                            <div class="plan-description">
                                <ul class="plan-features">
                                    <li class="border-top py-3">10GB Linux Web Space</li>
                                    <li class="border-top py-3">50 MySQL Databases</li>
                                    <li class="border-top py-3">Unlimited Email</li>
                                    <li class="border-top border-bottom py-3">Daily Backups</li>
                                </ul>
                            </div>
                            <!-- Plan Button -->
                            <div class="plan-button">
                                <a href="#" class="btn mt-4">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <p class="text-body d-block text-center mt-5 fw-5">Not sure what to choose? <a href="#">Contact Us</a></p>
                    <!-- sApp Accordion -->
                    <div class="accordion pt-5" id="apolo-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn p-2" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                How to install sApp?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed p-2" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                Can I get support from the Author?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed p-2" type="button" data-toggle="collapse" data-target="#collapseThree">
                                                Contact form isn't working?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed p-2" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                What about the events?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn p-2" type="button" data-toggle="collapse" data-target="#collapseFive">
                                                How can I get product update?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse show" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed p-2" type="button" data-toggle="collapse" data-target="#collapseSix">
                                                Is this template support rtl?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" data-parent="#apolo-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>