<section id="home" class="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-8">
                <div class="welcome-intro text-center">
                    <h1 class="text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">Capture and share your best memories with sApp</h1>
                    <p class="text-white my-4" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                    <div class="welcome-bottom" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
                        <!-- Store Buttons -->
                        <div class="button-group store-buttons d-flex justify-content-center">
                            <a href="#">
                                <img src="assets/img/google-play.png" alt="">
                            </a>
                            <a href="#">
                                <img src="assets/img/app-store.png" alt="">
                            </a>
                        </div>
                        <span class="d-inline-block text-white fw-3 font-italic mt-3">* Available on iPhone, iPad and all Android devices</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape-bottom">
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
        </svg>
    </div>
</section>