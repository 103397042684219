import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-corner',
  templateUrl: './whatsapp-corner.component.html',
  styleUrls: ['./whatsapp-corner.component.css']
})
export class WhatsAppCornerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
