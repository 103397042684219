import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { NumberFormatPipe } from "../../number.pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { SelectionModel } from "@angular/cdk/collections";
import { debounceTime } from "rxjs/operators";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DialogAlert } from "../accounts/profile/myprofile/detail-profile/detail-profile.component";
import { MatDialog } from "@angular/material/dialog";
import { VariantDetails } from "./cart";

declare var $: any;
declare interface TableData {
  headerRow: string[];
  dataRows: string[][];
}

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
  providers: [NumberFormatPipe, ApiService],
})
export class CartComponent implements OnInit {
  checklist = {
    name: "Pilih semua produk",
    completed: false,
    subChecklists: [],
    nonActiveList: [],
  };

  totalPrice: number = 0;
  total: any;
  variantId: number | null = null;
  deleted: any;
  dataSelection = new SelectionModel<any>(true, []);
  storeID: any;
  checked: boolean = false;
  allComplete: boolean = false;

  variantDetails = [];
  payloadCheckout: any = [];

  constructor(
    private snackBar: MatSnackBar,
    private service: ApiService,
    private currencyFormat: NumberFormatPipe,
    private routes: Router,
    private dialog: MatDialog,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  check() {
    this.service.login.next(true);
  }

  updateCart() {
    this.service.cartList()?.subscribe(
      (data) => {
        this.checklist.subChecklists = data.items;
        this.total = data.total;
        // console.log("Cart-Component-List")
        // console.log(data)
      },
      (err: any) => {
        console.log(err);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: "Gagal mengubah keranjang. Karena " + err.error.reason,
          },
        });
      }
    );
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price);
  }

  updateQuantity() {
    this.service.cartList().subscribe(
      (data) => {
        this.checklist.subChecklists = data.items;
        this.total = data.total;
        // console.log("Cart-Component-List")
        // console.log(data)
      },
      (e) => {
        // console.log(e);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: "Gagal mengubah keranjang. Karena " + e.error.reason,
          },
        });
      }
    );
  }

  //////////////////

  public tableData3: TableData;

  ngOnInit() {
    this.getCartList();
    // setInterval(()=>{console.log(this.selection);console.log(this.selection.selected)},1000)
  }

  getCartList() {
    this.service.cartList().subscribe(
      (data) => {
        this.checklist.subChecklists = data.items;
        this.checklist.nonActiveList = data.invalid_items;
        // console.log("panjang checklist: ", this.checklist.nonActiveList.length);
        this.total = data.total;
        // console.log("CartComponentList-cartList")
        // console.log("data cart: ", this.checklist.subChecklists);
      },
      (e) => {
        // console.log(e);
      }
    );
  }

  openSnackBar(message: any, action: any) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }

  onKeyUp(event: any) {
    const amount = event.target.value;
    const cartId = event.target.id;
    // console.log("amount: ", amount);
    // console.log("checklist: ", this.checklist.subChecklists);
    for (let i = 0; i < this.checklist.subChecklists.length; i++) {
      // console.log(this.checklist.subChecklists[i].products[i].quantity);
      var cart = {
        quantity: amount,
        cart_id: cartId,
      };
    }
    // console.log(cart);
    setTimeout(() => {
      this.service
        .editCart(cart)
        .pipe(debounceTime(5000))
        .subscribe(
          (data) => {
            // console.log("asdokaod")
            // console.log(data)
            // this.updateCart();
            // this.service.isUpdateCart.next(true);
          },
          (err: any) => {
            // console.log(err);
            this.dialog.open(DialogAlert, {
              width: "300px",
              data: {
                status: false,
                message:
                  "Gagal mengubah keranjang. " + "Karena " + err.error.reason,
              },
            });
          }
        );
    }, 1500);
  }

  deleteFromCart(event: any) {
    const variant_id = Number(event.target.id);
    // console.log(event.target.id);

    this.service.deleteCart(variant_id).subscribe(
      (data) => {
        // console.log(data)
        this.openSnackBar("Item berhasil dihapus dari keranjang", "Ok");
        this.service.deletedFromCart.next(true);
        this.updateCart();
      },
      (err: any) => {
        // console.log(err);
        this.dialog.open(DialogAlert, {
          width: "300px",
          data: {
            status: false,
            message: err.error.message,
          },
        });
      }
    );
  }

  getTotal() {
    let total = 0;
    for (let i = 0; i < this.tableData3.dataRows.length; i++) {
      const integer = parseInt(this.tableData3.dataRows[i][8], 10);
      total += integer;
    }
    return total;
  }

  plus(event: any) {
    const cartUpdates = [];
    for (let i = 0; i < this.checklist.subChecklists.length; i++) {
      for (
        let j = 0;
        j < this.checklist.subChecklists[i].products.length;
        j++
      ) {
        if (
          this.checklist.subChecklists[i].products[j].cart_id ===
          event.target.id
        ) {
          this.checklist.subChecklists[i].products[j].quantity += 1;

          const cart = {
            quantity: this.checklist.subChecklists[i].products[j].quantity,
            cart_id: parseInt(event.target.id),
          };

          cartUpdates.push(cart);
        }
      }
    }
    this.zone.runOutsideAngular(() => {
      for (const cartUpdate of cartUpdates) {
        this.service
          .editCart(cartUpdate)
          .pipe(debounceTime(5000))
          .subscribe(
            () => {
              this.zone.run(() => {
                this.dataSelection.clear();
                // this.updateCart();
              });
            },
            (err: any) => {
              this.zone.run(() => {
                this.dialog.open(DialogAlert, {
                  width: "300px",
                  data: {
                    status: false,
                    message:
                      "Gagal mengubah keranjang. " +
                      "Karena " +
                      err.error.reason,
                  },
                });
              });
            }
          );
      }
    });
  }

  minus(event: any) {
    const cartUpdates = [];
    for (let i = 0; i < this.checklist.subChecklists.length; i++) {
      for (
        let j = 0;
        j < this.checklist.subChecklists[i].products.length;
        j++
      ) {
        if (
          this.checklist.subChecklists[i].products[j].cart_id ===
          event.target.id
        ) {
          if (this.checklist.subChecklists[i].products[j].quantity < 1) {
            this.dialog.open(DialogAlert, {
              width: "300px",
              data: {
                status: false,
                message:
                  "Gagal mengubah keranjang. Karena Jumlah produk tidak boleh kurang dari 1",
              },
            });
          } else {
            this.checklist.subChecklists[i].products[j].quantity -= 1;

            const cart = {
              quantity: this.checklist.subChecklists[i].products[j].quantity,
              cart_id: parseInt(event.target.id),
            };
            cartUpdates.push(cart);
          }
        }
      }
    }
    this.zone.runOutsideAngular(() => {
      for (const cartUpdate of cartUpdates) {
        this.service
          .editCart(cartUpdate)
          .pipe(debounceTime(5000))
          .subscribe(
            (data) => {
              // console.log(data)
              this.zone.run(() => {
                this.dataSelection.clear();
                // this.updateCart();
              });
            },
            (err: any) => {
              this.zone.run(() => {
                this.dialog.open(DialogAlert, {
                  width: "300px",
                  data: {
                    status: false,
                    message:
                      "Gagal mengubah keranjang. " +
                      "Karena " +
                      err.error.reason,
                  },
                });
              });
            }
          );
      }
    });
  }

  isAllSelected() {
    const numSelected = this.dataSelection.selected.length;
    const numRows = this.getTotalProductsCount();

    return numRows > 0 && numSelected === numRows;
  }
  masterToggle() {
    if (this.checklist.subChecklists.length === 0) {
      return;
    }

    const allProducts = this.checklist.subChecklists.reduce(
      (acc, cart) => acc.concat(cart.products),
      []
    );

    if (this.isAllSelected()) {
      this.dataSelection.deselect(...allProducts);
    } else {
      this.dataSelection.select(...allProducts);
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.dataSelection.isSelected(row) ? "deselect" : "select"} row ${
      row.cart_id + 1
    }`;
  }

  getAllSelectedProducts() {
    const allSelectedProducts = [];

    this.checklist.subChecklists.forEach((cart) => {
      cart.product.forEach((product: any) => {
        if (this.dataSelection.isSelected(product)) {
          allSelectedProducts.push(product);
        }
      });
    });

    return allSelectedProducts;
  }

  getSelectedProduct(product: any) {
    if (this.dataSelection.isSelected(product)) {
      // console.log(this.dataSelection.selected);
    } else {
      // console.log(`Product ${product.cart_id} is deselected`);
    }
  }

  handleProductCheckboxChange(product: any) {
    const waybillNumber =
      product.cart_id !== undefined ? product.cart_id : "unknown";

    if (this.dataSelection.isSelected(product)) {
      // console.log(`Product ${waybillNumber} selected`);
    } else {
      // console.log(`Product ${waybillNumber} deselected`);
    }
    this.cdr.detectChanges();
  }

  getTotalProductsCount(): number {
    return this.checklist.subChecklists
      .map((cart) => cart.products.length)
      .reduce((acc, count) => acc + count, 0);
  }

  allSelected(): boolean {
    if (this.checklist.subChecklists == null) {
      return false;
    }
    return (
      this.checklist.subChecklists.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  onChecked(event: MatCheckboxChange) {
    var detail: VariantDetails = {
      cart_id: 0,
      product_id: 0,
      product_name: "",
      variant_id: 0,
      variant_name: "",
      quantity: 0,
      variant_price: 0,
      product_image: "",
      subtotal: 0,
    };
    // console.log(
    //   "Panjang data yang dipilih: ",
    //   this.dataSelection.selected.length
    // );

    if (event.checked === true) {
      // console.log(event.source.id)
      for (let i = 0; i < this.checklist.subChecklists.length; i++) {
        if (this.checklist.subChecklists[i].cart_id === event.source.id) {
          detail.cart_id = this.checklist.subChecklists[i].cart_id;
          detail.product_id =
            this.checklist.subChecklists[i].product_variant.product.product_id;
          detail.product_name =
            this.checklist.subChecklists[i].product_variant.product.name;
          detail.variant_id =
            this.checklist.subChecklists[i].product_variant.variant_id;
          detail.variant_name =
            this.checklist.subChecklists[i].product_variant.variant_name;
          detail.variant_price =
            this.checklist.subChecklists[i].product_variant.variant_price;
          detail.subtotal =
            this.checklist.subChecklists[i].product_variant.variant_price *
            this.checklist.subChecklists[i].quantity;
          detail.quantity = this.checklist.subChecklists[i].quantity;
          detail.product_image =
            this.checklist.subChecklists[
              i
            ].product_variant.product.main_photo_url;

          this.totalPrice = this.totalPrice + detail.subtotal;
          this.checked = true;
        }
      }
      this.variantDetails.push(detail);
    } else {
      for (let j = 0; j < this.variantDetails.length; j++) {
        if (this.variantDetails[j].cart_id === event.source.id) {
          this.totalPrice = this.totalPrice - this.variantDetails[j].subtotal;

          this.variantDetails.splice(j, 1);
        }
      }

      if (this.variantDetails.length <= 0) {
        this.checked = false;
      }
    }
    // console.log(this.variantDetails)
  }

  checkAll(event: MatCheckboxChange) {
    const sentThis = this.dataSelection.selected;
    // console.log(sentThis);
    if (sentThis.length === 0) {
      this.totalPrice = 0;
    }

    this.payloadCheckout = sentThis;
    this.totalPrice = 0;
    this.variantDetails = [];
    // const items = [];

    sentThis.forEach((value: any) => {
      const cart_detect = true;
      const cart_id = Number(value.cart_id);
      const store_id = Number(value.store_id);
      const store_name = value.store_name;
      const product_id = Number(value.product_id);
      const product_name = value.name;
      const variant_id = Number(value.product_variant.variant_id);
      const variant_name = value.product_variant.variant_name;
      const variant_price = value.product_variant.variant_price;
      const variant_stock = value.product_variant.variant_stock;
      const subtotal = value.product_variant.variant_price * value.quantity;
      const quantity = value.quantity;
      const product_image = value.main_photo_url;

      const existingItemIndex = this.variantDetails.findIndex(
        (item) => item.store_id === store_id
      );
      if (existingItemIndex !== -1) {
        this.variantDetails[existingItemIndex].products.push({
          cart_detect,
          cart_id,
          product_id,
          product_name,
          variant_id,
          variant_name,
          variant_price,
          variant_stock,
          subtotal,
          quantity,
          product_image,
        });
      } else {
        this.variantDetails.push({
          cart_detect,
          store_id,
          store_name,
          products: [
            {
              cart_id,
              product_id,
              product_name,
              variant_id,
              variant_name,
              variant_price,
              variant_stock,
              subtotal,
              quantity,
              product_image,
            },
          ],
        });
      }

      this.totalPrice += subtotal;
    });

    console.log(
      "this.variantDetails grouped by store_id: ",
      this.variantDetails
    );
    console.log("Event checked: ", event.checked);
  }

  checkout() {
    this.routes.navigate(["/checkout"], {
      queryParams: {
        orderInfo: btoa(JSON.stringify(this.variantDetails)),
        totalPrice: this.totalPrice,
      },
    });
  }
}
