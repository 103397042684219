<div>
  <h3 style="text-align: center">{{action}} Alamat</h3>

  <mat-dialog-content class="pb-3 pt-4" *ngIf="action === 'Tambah'">
    <form #userForm="ngForm">
      <div class="form-group  align-items-center">
        <label>Nama Penerima</label>
        <div>
          <input class="form-control" type="text" required id="recipient" name="recipient"
            [(ngModel)]="local_data.recipient" placeholder="Nama Penerima" />
        </div>
      </div>
      <div class="form-group  align-items-center">
        <label>No Telepon</label>
        <div>
          <input class="form-control" type="text" required id="phone" name="phone" [(ngModel)]="local_data.phone"
            placeholder="Nomor yang dapat dihubungi" minlength="8" maxlength="16" />
          <mat-hint>
            <small> Format no hp : 08xxxxxxx </small>
          </mat-hint>
        </div>
      </div>
      <div class="form-group  align-items-center">
        <label>Kategori Alamat</label>
        <div>
          <select class="form-control selectpicker clickable" required id="category" name="category"
            [(ngModel)]="local_data.category">
            <option value="" disabled selected="selected">
              - Pilih Kategori Alamat -
            </option>
            <option value="Rumah">Rumah</option>
            <option value="Kantor">Kantor</option>
          </select>
        </div>
      </div>

      <div class="form-group  align-items-center">
        <label>Kota atau Kecamatan</label>
        <div>
          <input class="form-control" required [(ngModel)]="local_data.address" name="address"
            [formControl]="form.controls['dest']" placeholder="Ketik Kota atau Kecamatan" aria-label="province"
            [matAutocomplete]="autoDist" />
          <mat-autocomplete autoActiveFirstOption #autoDist="matAutocomplete" [displayWith]="displayFnDest">
            <mat-option *ngFor="let option of destinationSearchData | async" [value]="option">
              <span>{{option.urban_name}}, {{option.sub_district_name}},
                {{option.city_name}}, Prov.{{option.province_name}},
                {{option.postal_code}}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="form-group  align-items-center">
        <label>Alamat</label>
        <div>
          <input type="text" class="form-control" required id="address_input" name="address_input"
            [(ngModel)]="local_data.address_input" (input)="addressInputChange($event)"
            placeholder="Ketik nama jalan / gedung / nomor rumah" />
        </div>
      </div>
      <div class="form-group  align-items-center">
        <label>Tandai Lokasi</label>
        <div class="origin-maps" id="map" style="width: 100%;height: 300px; margin-bottom: 20px;margin-top: 0px;">
        </div>
        <label class="map-label"><strong>NOTE</strong>: Klik pada titik spesifik untuk lokasi pengantaran<span
            style="color: red">*</span></label>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-4 ">Alamat Utama</label>
        <div class="col-4">
          <mat-slide-toggle id="primary_address" name="primary_address"
            [(ngModel)]="local_data.primary_address"></mat-slide-toggle>
        </div>
      </div>
      <div class="button-wrapper d-flex pt-3">
        <button class="btn" style="background: #b6244f !important; color: #fff" (click)="closeDialog()">
          Cancel
        </button>
        <button style="color: white" class="btn" (click)="doAction()" [disabled]="!userForm.valid">
          {{action}}
        </button>

      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-content class="pb-3" *ngIf="action === 'Ubah'">
    <form #userForm="ngForm">
      <div class="form-group  align-items-center">
        <label>Nama Penerima</label>
        <div>
          <input class="form-control" type="text" required id="recipient" name="recipient"
            [(ngModel)]="local_data.recipient" placeholder="Nama Penerima" />
        </div>
      </div>
      <div class="form-group align-items-center">
        <label>No Telpon</label>
        <div>
          <input class="form-control" type="text" required id="phone" name="phone" [(ngModel)]="local_data.phone"
            placeholder="Nomor yang dapat dihubungi"
            (input)="local_data.phone = transformPhoneNumber($event.target.value)" />
          <mat-hint>
            <small> Format no hp : 08xxxxxxx </small>
          </mat-hint>
        </div>
      </div>
      <div class="form-group  align-items-center">
        <label>Kategori Alamat</label>
        <div>
          <select class="form-control selectpicker" required id="category" name="category"
            [(ngModel)]="local_data.category">
            <option value="Rumah">Rumah</option>
            <option value="Kantor">Kantor</option>
          </select>
        </div>
      </div>

      <div class="form-group  align-items-center">
        <label>Kota atau Kecamatan</label>
        <div>
          <input class="form-control" required [(ngModel)]="local_data.address" name="address"
            [formControl]="form.controls['dest']" placeholder="Ketik Kota atau Kecamatan" aria-label="province"
            [matAutocomplete]="autoDist" style="padding-left: 21px; width: 280px" />
          <mat-autocomplete autoActiveFirstOption #autoDist="matAutocomplete" [displayWith]="displayFnDest">
            <mat-option *ngFor="let option of destinationSearchData | async" [value]="option">
              <span>{{option.urban_name}}, {{option.sub_district_name}},
                {{option.city_name}}, Prov.{{option.province_name}},
                {{option.postal_code}}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="form-group  align-items-center">
        <label>Alamat</label>
        <div>
          <input type="text" class="form-control" required id="address_input" name="address_input"
            [(ngModel)]="local_data.address_input" (input)="addressInputChange($event)"
            placeholder="Ketik nama jalan / gedung / nomor rumah" />
        </div>
      </div>
      <div class="form-group  align-items-center">
        <label>Tandai Lokasi</label>
        <div class="origin-maps" id="map" style="width: 100%;height: 300px; margin-bottom: 20px;margin-top: 0px;">
        </div>
        <label class="map-label"><strong>NOTE</strong>: Klik pada titik spesifik untuk lokasi pengantaran<span
            style="color: red">*</span></label>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-4 ">Alamat Utama</label>
        <div class="col-4">
          <mat-slide-toggle id="primary_address" name="primary_address"
            [(ngModel)]="local_data.primary_address"></mat-slide-toggle>
        </div>
      </div>
      <div class="button-wrapper d-flex pt-3">
        <button class="btn" style="background: #b6244f !important; color: #fff" (click)="closeDialog()">
          Cancel
        </button>
        <button style="color: white" class="btn" (click)="doAction()" [disabled]="!userForm.valid">
          {{action}}
        </button>

      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-content class="pb-3" *ngIf="action === 'Hapus'">
    <form #userForm="ngForm">
      <div class="form-group row align-items-center">
        <p style="text-align: center">
          Apakah Anda yakin untuk menghapus
          <b> "{{local_data.category}} {{local_data.recipient}}"</b>?<br />Anda
          tidak dapat mengembalikan alamat yang sudah dihapus.
        </p>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-center pt-3">
        <div class="p-2">
          <button class="btn" style="background: #b6244f !important; color: #fff" (click)="closeDialog()">
            Cancel
          </button>
        </div>
        <div class="p-2">
          <button style="color: white" class="btn" (click)="doAction()" [disabled]="!userForm.valid">
            {{action}}
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>