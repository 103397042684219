<div class="homepage-2">
    <div class="main">
        <app-scrollup></app-scrollup>
        <app-header-two></app-header-two>
        <app-welcome-two></app-welcome-two>
        <app-feature-two></app-feature-two>
        <app-discover-two></app-discover-two>
        <app-service-two></app-service-two>
        <app-work></app-work>
        <app-screenshot-two></app-screenshot-two>
        <app-review-two></app-review-two>
        <app-pricing-one></app-pricing-one>
        <app-faq-two></app-faq-two>
        <app-download></app-download>
        <app-subscribe></app-subscribe>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>