<div class="message" style="background: #fff;height: 100%;">
  <div class="message-header">
    <div class="d-flex align-items-center h-100 mx-3" style="gap: 10px;">
      <mat-icon class="back-icon" (click)="onBackClick()">keyboard_backspace</mat-icon>
      <div class="user_img">
        <img [src]="activeProfilePicture" alt="user_image" (error)="onImageError($event)">
      </div>
      <a [href]="storeUrl">
        <h4 class="m-0 font-weight-bold"> {{storename}}</h4>
        <h5 class="m-0 text-muted">{{onlineStatus}}</h5>
      </a>
    </div>

  </div>
  <mat-divider></mat-divider>
  <div *ngIf="fetchLoading" style="position: absolute; left: 50%; ">
    <div style="position: relative; left: -50%;top: 10px;">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  </div>
  <div class="message-wrapper">
    <div #messageContainer class="message-container">
      <ng-container *ngFor="let message of message">
        <div *ngIf="message.type ==='text'" [ngClass]="{'from-me': loggedinUser === message.from, 'bubble': true}">
          <p [innerHTML]="formatMessage(message.content.text)"></p>
          <div class="row d-flex justify-content-end " style="gap:5px">
            <p class="m-0 text-muted" style="font-size: 10px;text-align: end;">{{message.timestamp | date :
              "dd/MM/YY HH:mm"}} </p>
            <mat-icon class="read-badge" [ngClass]="{'read': message.read}">check</mat-icon>
          </div>
        </div>

        <div *ngIf="message.type ==='product'" [ngClass]="{'from-me': loggedinUser === message.from, 'bubble': true}">
          <div class="product">
            <a [href]="message.content.product.url" target="_blank">
              <img [src]="message.content.product.product_image" alt="product-image" />
              <div>
                <h5>{{message.content.product.product_name}}</h5>
                <span class="text-muted">Variant: {{message.content.product.variant_name}}</span>
                <h5>Rp.{{priceTransform(message.content.product.price)}}</h5>
              </div>
            </a>
          </div>
          <div class="row d-flex justify-content-end " style="gap:5px">
            <p class="m-0 text-muted" style="font-size: 10px;text-align: end;">{{message.timestamp | date :
              "dd/MM/YY HH:mm"}} </p>
            <mat-icon class="read-badge" [ngClass]="{'read': message.read}">check</mat-icon>
          </div>
        </div>

        <div *ngIf="message.type ==='invoice'" [ngClass]="{'from-me': loggedinUser === message.from, 'bubble': true}">
          <div class="invoice">
            <a href="/user/order" target="_blank">
              <img [src]="message.content.invoice_items[0].photo_url[0]" alt="product-image" />
              <div>
                <p class="mb-0 text-center highlight-bb">
                  {{ statusTransform(message.content.invoice_status) }}
                </p>
                <h5>{{message.content.invoice}}</h5>
                <h5 class="price">Rp.{{priceTransform(message.content.invoice_payment.total_payment)}}</h5>
              </div>
            </a>
          </div>
          <div class="row d-flex justify-content-end " style="gap:5px">
            <p class="m-0 text-muted" style="font-size: 10px;text-align: end;">{{message.timestamp | date :
              "dd/MM/YY HH:mm"}} </p>
            <mat-icon class="read-badge" [ngClass]="{'read': message.read}">check</mat-icon>
          </div>
        </div>

        <div *ngIf="message.type ==='image'" [ngClass]="{'from-me': loggedinUser === message.from, 'bubble': true}">
          <div class="image" style="max-width: 250px;object-fit: contain;">
            <img [src]="message.content.imageUrl" alt="image" (click)="viewImage(message.content.imageUrl)"
              style="cursor: pointer;border-radius: 5px;" />
          </div>
          <div class="row d-flex justify-content-end " style="gap:5px">
            <p class="m-0 text-muted" style="font-size: 10px;text-align: end;">{{message.timestamp | date :
              "dd/MM/YY HH:mm"}} </p>
            <mat-icon class="read-badge" [ngClass]="{'read': message.read}">check</mat-icon>
          </div>
        </div>

      </ng-container>
    </div>

    <div class="product-wrapper p-2" *ngIf="productMessageDetail">
      <div class="product-card">
        <div class="relative">
          <div (click)="removeProduct()" class="close-btn">
            <mat-icon>close</mat-icon>
          </div>
          <a [href]="productMessageDetail.url" target="_blank">
            <img [src]="productMessageDetail.productImage" alt="product-image" />
            <div>
              <h5>{{productMessageDetail.productName}}</h5>
              <span class="text-muted">Variant: {{productMessageDetail.variantName}}</span>
              <h5>Rp.{{priceTransform(productMessageDetail.price)}}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="order-wrapper p-2" *ngIf="orderMessage">
      <div class="order-card">
        <div class="relative">
          <div (click)="removeOrder()" class="close-btn">
            <mat-icon>close</mat-icon>
          </div>
          <a href="/user/order" target="_blank">
            <img [src]="orderMessage.invoice_items[0].photo_url[0]" alt="product-image" />
            <div>
              <p class="mb-0 text-center highlight-bb">
                {{ statusTransform(orderMessage.invoice_status) }}
              </p>
              <h5>{{orderMessage.invoice}}</h5>
              <h5>Rp.{{priceTransform(orderMessage.invoice_payment.total_payment)}}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="message-footer" style="min-height: 80px;">
      <div class="d-flex align-items-center justify-content-center p-2" style="width: 100%;gap:10px;">
        <input type="file" (change)="handleFileInput($event.target.files)" accept="image/png, image/jpeg" #Image
          style="display: none;" />
        <mat-icon style="position:  absolute; left: 25px;cursor: pointer;"
          (click)="Image.click()">add_photo_alternate</mat-icon>
        <textarea matInput [(ngModel)]="newMessage" (keydown)="handleKeyDown($event)" placeholder="Ketik pesan"
          rows="1"></textarea>
        <div class="d-flex justify-content-center align-items-center"
          style="width: 50px;background: #d4d4d8; border-radius: 50%;height: 50px;cursor:pointer" matTooltip="Kirim"
          matTooltipPosition="above" (click)="handleSendMessage()"><mat-icon>send</mat-icon></div>
      </div>
    </div>
  </div>
</div>