<h3 mat-dialog-title>
  <strong>{{ title }}</strong>
</h3>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-button (click)="onDismiss()">No</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
</div>
