<div style="height: 100%;position: relative;">
  <div mat-dialog-title style="margin: 0">
    <div style="display: flex; align-items: center; width: 100%" class="pb-3 pt-1">
      <h3 style="font-weight: 500; margin: 0; flex: 1">Penilaian</h3>
      <mat-icon style="text-align: end; margin-right: 10px; cursor: pointer" mat-dialog-close>close</mat-icon>
    </div>
    <mat-divider class="py-2"></mat-divider>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="reviewForm">
      <div formArrayName="reviews">
        <div *ngFor="let item of reviews.controls; let i = index" [formGroupName]="i">
          <div class="card">
            <!-- Product -->
            <div class="product-wrapper">
              <img [src]="item.get('photo').value" alt="product-photo" width="70" />
              <div>
                <h4>{{ item.get('name').value }}</h4>
                <h5 class="text-muted">Variant: {{ item.get('variant_name').value }}</h5>
              </div>
            </div>
            <!-- end product -->

            <!-- stars -->
            <div class="star-wrapper">
              <mat-icon *ngFor="let number of [1,2,3,4,5]; let j = index"
                [ngClass]="{ 'active': j + 1 <= item.get('rating').value }" (click)="selectStar(i, j + 1)">
                star
              </mat-icon>
            </div>
            <!-- end stars -->
            <!-- description -->
            <div class="description-wrapper" *ngIf="item.get('rating').value > 0">
              <h5>Ceritakan kenapa kamu merasa {{ transformStar(item.get('rating').value) }}</h5>

              <textarea matInput rows="2" formControlName="description" required maxlength="255">
              </textarea>

              <div class="media-wrapper">
                <h5>Biar makin komplit, unggah foto dan video produk</h5>

                <!-- Image Upload Section -->
                <div class="images-wrapper">
                  <ng-container formArrayName="images">
                    <div *ngFor="let image of item.get('images').controls; let imageIndex = index"
                      [formGroupName]="imageIndex">
                      <div class="image-wall" *ngIf="image.get('status').value === 'done'">
                        <img [src]="image.get('url').value" alt="image">
                        <div class="button-action">
                          <mat-icon (click)="deleteImage(i, imageIndex)">delete</mat-icon>
                          <mat-icon (click)="viewImage(image.get('url').value)">visibility</mat-icon>
                        </div>
                      </div>
                      <div class="upload-loading" *ngIf="image.get('status').value === 'uploading'">
                        <mat-progress-spinner color="primary" mode="determinate" [value]="image.get('progress').value"
                          diameter="30">
                        </mat-progress-spinner>
                        <span>Uploading</span>
                      </div>
                    </div>
                  </ng-container>

                  <div class="add-image" (click)="Image.click()" *ngIf="item.get('images').value.length < 5">
                    <mat-icon>add</mat-icon>
                    <span>Unggah Gambar</span>
                  </div>

                  <input type="file" (change)="handleImageUpload(i, $event.target.files)" accept="image/png, image/jpeg"
                    #Image style="display: none;" />
                </div>

                <!-- Video Upload Section -->
                <div class="video-wrapper">
                  <ng-container *ngIf="item.get('video').value">
                    <div class="video-wall" *ngIf="item.get('video').value.status === 'done'">
                      <video [src]="item.get('video').value.url" alt="video" controls></video>
                      <div class="button-action">
                        <mat-icon (click)="deleteVideo(i)">delete</mat-icon>
                        <mat-icon (click)="viewVideo(item.get('video').value.url)">visibility</mat-icon>
                      </div>
                    </div>

                    <div class="upload-loading" *ngIf="item.get('video').value.status === 'uploading'">
                      <mat-progress-spinner color="primary"
                        [mode]="item.get('video').value.progress < 100 ? 'determinate' : 'indeterminate'"
                        [value]="item.get('video').value.progress" diameter="30">
                      </mat-progress-spinner>
                      <span>{{ item.get('video').value.progress < 100 ? 'Uploading' : 'Compressing' }}</span>
                    </div>
                  </ng-container>


                  <div class="add-video" *ngIf="!item.get('video').value" (click)="Video.click()">
                    <mat-icon>add</mat-icon>
                    <span>Unggah Video</span>
                  </div>

                  <input type="file" (change)="handleVideoUpload(i, $event.target.files)" accept="video/mp4" #Video
                    style="display: none;" />
                </div>
              </div>

              <!-- Hide Username Toggle -->
              <mat-slide-toggle class="mt-3" formControlName="hide_username" color="primary"
                [checked]="item.get('hide_username').value">
                Samarkan nama
              </mat-slide-toggle>
              <!-- end hide username toggle -->
            </div>
            <!-- end description -->

          </div>
        </div>
      </div>
    </form>

    <!-- <div class="card"> -->
    <!-- <div class="product-wrapper">
        <img [src]="item.photo" alt="product-photo" width="70" />
        <div>
          <h4>{{item.name}}</h4>
          <h5 class="text-muted">Varian : {{item.variant_name}}</h5>
        </div>
      </div> -->
    <!-- <div class="star-wrapper">
        <mat-icon *ngFor="let number of [1,2,3,4,5];let j=index" [ngClass]="{
        'active': j + 1 <= item.rating 
        }" (click)="selectStar(i,j+1)">star</mat-icon>
      </div> -->
    <!-- <div class="description-wrapper" *ngIf="item.rating>0">
        <h5>Ceritakan kenapa kamu merasa {{transformStar(item.rating)}}</h5>
        <textarea matInput rows="2" [(ngModel)]="item.description" required maxlength="255"></textarea>

        <div class="media-wrapper">
          <h5>Biar makin komplit, unggah foto dan video produk</h5>
          <div class="images-wrapper">
            <ng-container *ngFor="let image of item.images;let imageIndex = index">
              <div class="image-wall" *ngIf="image.status==='done'">
                <img [src]="image.url" alt="image">
                <div class="button-action">
                  <mat-icon (click)="deleteImage(i,imageIndex)">delete</mat-icon>
                  <mat-icon (click)="viewImage(image.url)">visibility</mat-icon>
                </div>
              </div>
              <div class="upload-loading" *ngIf="image.status==='uploading'">
                <mat-progress-spinner color="primary" mode="determinate" [value]="image.progress" diameter="30"
                  strokeWidth="">
                </mat-progress-spinner>
                <span>Uploading</span>
              </div>
            </ng-container>

            <div class="add-image" (click)="Image.click()" *ngIf="item.images.length<5">
              <mat-icon>add</mat-icon>
              <span>Unggah Gambar</span>
            </div>
            <input type="file" (change)="handleImageUpload(i,$event.target.files)" accept="image/png, image/jpeg" #Image
              style="display: none;" />
          </div>
          <div class="video-wrapper">
            <div class="video-wall" *ngIf="item.video?.status==='done'">
              <video [src]="item.video.url" alt="video"></video>
              <div class="button-action">
                <mat-icon (click)="deleteVideo(i)">delete</mat-icon>
                <mat-icon (click)="viewVideo(item.video.url)">visibility</mat-icon>
              </div>
            </div>
            <div class="upload-loading" *ngIf="item.video?.status==='uploading'">
              <mat-progress-spinner color="primary" [mode]="item.video.progress<100?'determinate':'indeterminate'"
                [value]="item.video.progress" diameter="30" strokeWidth="">
              </mat-progress-spinner>
              <span>{{item.video.progress<100?'Uploading':'Compressing'}} </span>
            </div>
            <div class="add-video" *ngIf="!item.video" (click)="Video.click()">
              <mat-icon>add</mat-icon>
              <span>Unggah Video</span>
            </div>
            <input type="file" (change)="handleVideoUpload(i,$event.target.files)" accept="video/mp4" #Video
              style="display: none;" />
          </div>
        </div>
      </div> -->
    <!-- </div> -->
  </div>

  <div mat-dialog-actions style="position: absolute;bottom: 0;width: 100%;">
    <div class="d-flex justify-content-end w-100" style="gap:5px">
      <button class="btn cancel" mat-dialog-close>Batal</button>
      <button class="btn" (click)="submit()" [disabled]="!reviewForm.valid && !uploadLoading ">Nilai</button>
    </div>
  </div>
</div>