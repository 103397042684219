<!-- <mat-dialog-content style="overflow: hidden !important">
  <mat-card class="mat-elevation-z12">
    <mat-card-title style="text-align: center; padding-bottom: 2%">
      <h3>Masuk</h3>
    </mat-card-title>
    <mat-card-content>
      <form
        class="mt-4"
        style="text-align: center"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
      >
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Username/Email"
            style="color: #262626"
            required
            [formControl]="form.controls['username']"
          />
          <small
            *ngIf="
              form.controls['username'].hasError('required') &&
              form.controls['username'].touched
            "
            class="text-danger support-text"
            >Username is Required</small
          >
          <small
            *ngIf="InvalidEmailPassword[0]"
            class="text-danger support-text"
            ><br />Invalid Username/Email</small
          >
          <small
            *ngIf="InvalidEmailPassword[2]"
            class="text-danger support-text"
            ><br />Username is Disabled</small
          >
          <mat-icon matSuffix style="color: #262626">person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Password"
            type="password"
            required
            minlength="6"
            matInput
            [type]="hide ? 'password' : 'text'"
            [formControl]="form.controls['password']"
          />
          <small
            *ngIf="
              form.controls['password'].hasError('required') &&
              form.controls['password'].touched
            "
            class="text-danger support-text"
            >Password is Required</small
          >
          <small
            *ngIf="InvalidEmailPassword[1]"
            class="text-danger support-text"
            ><br />Invalid Password</small
          >
          <span
            required="required"
            style="cursor: pointer"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon
              style="font-size: 21px; padding-bottom: 30px; color: #262626"
              >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
            >
          </span>
        </mat-form-field>
        <small *ngIf="InvalidCredential" class="text-danger support-text"
          ><br />Username / password salah</small
        >
        <button
          color="primary"
          class="login-button btn btn-lg w-100"
          type="submit"
          [disabled]="!form.valid"
        >
          Sign In
        </button>
      </form>
      <span style="font-size: 12px; margin-top: 5px">
        <a href="/reset" style="color: #b6244f">Forgot password?</a>
      </span>
    </mat-card-content>
    <mat-card-content>
      <span style="font-size: 12px; margin-top: 5px; align-items: center">
        Don't have an account?
        <a href="/signup" style="color: #b6244f">Daftar</a>
      </span>
    </mat-card-content>
  </mat-card>
</mat-dialog-content> -->

<div style="overflow: hidden">
  <app-main-header></app-main-header>

  <div class="container">
    <div class="image-container">
      <img src="https://storage.onindonesia.id/onmarket/Banner 4;3[1] 1.png" alt="logo-login" />
    </div>
    <div class="login-container">
      <mat-card class="login-card">
        <mat-card-title class="login-title">
          <h3 style="font-size: 30px">Masuk</h3>
        </mat-card-title>
        <mat-card-content class="login-content">
          <form class="mt-4" style="text-align: left" [formGroup]="form" (ngSubmit)="onSubmit()">
            <h4 style="font-weight: 600; padding-bottom: 6px">
              Username atau Email
            </h4>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Username/Email" style="color: #262626" required
                [formControl]="form.controls['username']" />
              <small *ngIf="
                  form.controls['username'].hasError('required') &&
                  form.controls['username'].touched
                " class="text-danger support-text">Username is Required</small>
              <small *ngIf="InvalidEmailPassword[0]" class="text-danger support-text"><br />Invalid
                Username/Email</small>
              <small *ngIf="InvalidEmailPassword[2]" class="text-danger support-text"><br />Username is Disabled</small>
              <mat-icon matSuffix style="color: #262626">person</mat-icon>
            </mat-form-field>
            <h4 style="font-weight: 600; padding-bottom: 6px">Kata Sandi</h4>
            <mat-form-field appearance="outline">
              <input matInput placeholder="Masukkan kata sandi" type="password" required minlength="6" matInput
                [type]="hide ? 'password' : 'text'" [formControl]="form.controls['password']" />
              <small *ngIf="
                  form.controls['password'].hasError('required') &&
                  form.controls['password'].touched
                " class="text-danger support-text">Password is Required</small>
              <small *ngIf="InvalidEmailPassword[1]" class="text-danger support-text"><br />Invalid Password</small>
              <span required="required" style="cursor: pointer" mat-icon-button matSuffix (click)="hide = !hide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon style="font-size: 21px; padding-bottom: 30px; color: #262626">{{ hide ? "visibility_off" :
                  "visibility" }}</mat-icon>
              </span>
            </mat-form-field>
            <small *ngIf="InvalidCredential" class="text-danger support-text"><br />Username / password salah</small>
            <div class="remember-me">
              <mat-checkbox [formControl]="form.controls['rememberMe']">
                Ingat Saya
              </mat-checkbox>
              <span>
                <a href="/reset">Lupa kata sandi?</a>
              </span>
            </div>

            <button color="primary" class="login-button btn btn-lg" type="submit" [disabled]="!form.valid">
              Sign In
            </button>
          </form>
        </mat-card-content>
        <div>
          <span style="font-size: 12px">
            Tidak punya akun?
            <a href="/signup" style="color: #2563eb; font-weight: 600">Daftar Akun</a>
          </span>
        </div>
      </mat-card>
    </div>
  </div>
  <footer class="mb-2">
    <div class="row text-align-center justify-content-center" style="gap: 2%">
      <div>
        <a href="about-us"> About us </a>
      </div>
      <div>
        <a href="faq"> FAQ </a>
      </div>
      <div>
        <a href="help"> Pusat Bantuan </a>
      </div>
    </div>
    <div class="row text-align-center justify-content-center">
      © Copyrights 2024 On Market. All rights reserved.
    </div>
  </footer>
</div>