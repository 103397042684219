import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {
  productName: string = ''
  productPrice: string = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private clipboard: Clipboard,) {
    const selectedVariant = this.data.product.product_variants.filter((item) => { return item.variant_id === this.data.selected })
    this.productPrice =
      new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(selectedVariant[0].variant_price);
    this.productName = this.data.product.name
  }

  ngOnInit(): void {
  }

  copy() {
    this.clipboard.copy(
      this.data.url
    );
    this.openSnackBar("Tautan berhasil di salin", "Ok");
  }

  shareWhatsapp() {
    const text = `Temukan ${this.productName} cuma ${this.productPrice} di Onmarket\n${this.data.url}`
    const encodedMessage = encodeURIComponent(text);
    window.open(`whatsapp://send?text=${encodedMessage}`, '_blank')
  }

  shareFacebook() {
    const text = `${this.data.url}Temukan ${this.productName} cuma ${this.productPrice} di Onmarket`
    const encodedMessage = encodeURIComponent(text);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`, '_blank')
  }

  shareTelegram() {
    const text = encodeURIComponent(`Temukan ${this.productName} cuma ${this.productPrice} di Onmarket`);
    const uri = encodeURIComponent(this.data.url);
    window.open(`https://t.me/share/url?url=${uri}&text=${text}`, '_blank')
  }

  shareTwitter() {
    const text = encodeURIComponent(`Temukan ${this.productName} cuma ${this.productPrice} di Onmarket`);
    const uri = encodeURIComponent(this.data.url);
    window.open(`https://twitter.com/intent/tweet?url=${uri}&text=${text}`, '_blank')
  }

  shareEmail() {
    const subject = encodeURIComponent(`Temukan ${this.productName} di Onmarket`)
    const body = encodeURIComponent(`Temukan ${this.productName} cuma ${this.productPrice} di Onmarket\n${this.data.url}`);
    window.open(`mailto:?subject=${subject}&body=${body}`, '_blank')
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: "top",
      panelClass: "center",
    });
  }
}
