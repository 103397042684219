import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NumberFormatPipe, VoucherFormatPipe } from 'src/app/number.pipe';

interface voucherDetails {
  voucher_id: string;
  name: string;
  code: string;
  description: string;
  terms: string;
  expired_date: string;
  start_date: string;
  max_discount: number;
  min_price: number;
  image_url: string;
  type: string;
  quota: number;
  value: number;
}

@Component({
  selector: 'app-select-voucher',
  templateUrl: './select-voucher.component.html',
  styleUrls: ['./select-voucher.component.css'],
  providers: [NumberFormatPipe, VoucherFormatPipe]
})
export class SelectVoucherComponent implements OnInit {
  @Output() claimVoucherEvent = new EventEmitter<string>();
  isLoading: boolean = false
  panelOpenState = false;
  termsOpenState = false;
  voucher_id: number
  voucher: voucherDetails = {
    voucher_id: "",
    name: "",
    code: "",
    description: "",
    terms: "",
    expired_date: "",
    start_date: "",
    max_discount: 0,
    min_price: 0,
    image_url: "",
    type: "",
    quota: 0,
    value: 0
  }
  constructor(
    public dialogRef: MatDialogRef<SelectVoucherComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private currencyFormat: NumberFormatPipe,
    private voucherFormat: VoucherFormatPipe,
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    // this.voucher = this.data.voucher

    if (this.data.voucher.length > 0) {
      for (let i = 0; i < this.data.voucher.length; i++) {
        if (this.data.voucher[i].min_price !== null && this.data.voucher[i].min_price <= this.data.currentPrice) {
          this.data.voucher[i].allow_claim = true
        }
      }
    }

    this.voucher_id = this.data.selectedVoucher

    // console.log('this.data')
    // console.log(this.data, 'dataadsawa')
    // console.log(this.data.voucher, 'dataadsawa')

    this.isLoading = false
  }

  priceTransform(price: number) {
    return this.currencyFormat.transform(price)
  }

  voucherTransform(value: number) {
    return this.voucherFormat.transform(value)
  }

  onSelectVoucher(voucher: voucherDetails) {
    var voucherTitle = ''
    if (voucher.type === 'percentage') {
      voucherTitle = voucher.value + "%"
    } else {
      voucherTitle = "Rp " + this.voucherTransform(voucher.value)
    }
    const data = {
      voucher: voucher,
      voucherName : voucherTitle
    }
    if (voucher) {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(null);
    }
  }

}
