<section id="home" class="section welcome-area bg-inherit h-100vh overflow-hidden">
    <div class="shapes-container">
        <div class="bg-shape"></div>
    </div>
    <div class="container h-100">
        <div class="row align-items-center h-100">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7">
                <div class="welcome-intro">
                    <h1>Creative way to Showcase your App</h1>
                    <p class="my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus commodi, voluptate quaerat iure quidem expedita eos a blanditiis sint modi est error veniam facere eum at doloribus amet, nobis ut.</p>
                    <a href="#" class="btn">Get Started</a>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb" data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                    <img src="assets/img/features_thumb.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>