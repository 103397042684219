<ng-template [ngIf]="!isLoading">
  <div class="p-2">
    <h3 style="text-align: center">Detail Voucher</h3>
    <div class="row">
      <div class="col-sm-8">
        <div class="card overflow-auto" style="height: 365px">
          <img
            src="{{ voucher.image_url }}"
            class="card-img-top"
            style="
              border-radius: 3px;
              border-color: rgb(204, 204, 204);
              height: 182.55px;
              width: 800px;
              object-fit: cover;
            "
          />
          <div class="card-body">
            <h4
              class="card-title"
              style="font-weight: bold; padding-bottom: 5px"
            >
              {{ voucher.name }}
            </h4>
            <p class="card-title" style="font-weight: bold; margin-bottom: 0px">
              Masa Berlaku
            </p>
            <p class="card-text" style="padding-bottom: 15px">
              {{ voucher.start_date }} - {{ voucher.expired_date }}
            </p>
            <p class="card-title" style="font-weight: bold; margin-bottom: 0px">
              Deskripsi
            </p>
            <p
              class="card-text"
              [innerText]="voucher.description"
              style="padding-bottom: 15px"
            ></p>
            <p class="card-title" style="font-weight: bold; margin-bottom: 0px">
              Syarat dan Ketentuan
            </p>
            <!-- <p class="card-text" [innerHTML]="voucher.description" style="padding-bottom: 5px;"></p> -->
            <p
              class="card-text"
              [innerText]="voucher.terms"
              style="padding-bottom: 5px"
            ></p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div
            class="card-header"
            style="font-weight: bold; background-color: white"
          >
            {{ voucher.name }}
          </div>
          <div class="card-body">
            <ng-template [ngIf]="voucher.type == 'percentage'">
              <p
                class="card-text"
                style="font-weight: bold; margin-bottom: 0px"
              >
                Diskon {{ voucher.value }}%
              </p>
            </ng-template>
            <ng-template [ngIf]="voucher.type == 'numeric'">
              <p
                class="card-text"
                style="font-weight: bold; margin-bottom: 0px"
              >
                Diskon Rp {{ voucherTransform(voucher.value) }}
              </p>
            </ng-template>
            <ng-template [ngIf]="voucher.min_price">
              <p class="card-text" style="margin-bottom: 0px">
                Min. Blj Rp{{ voucherTransform(voucher.min_price) }}
              </p>
            </ng-template>
            <ng-template [ngIf]="voucher.max_discount">
              <p class="card-text" style="margin-bottom: 0px">
                Max. Disc Rp{{ voucherTransform(voucher.max_discount) }}
              </p>
            </ng-template>
            <p class="card-text">S/D: {{ voucher.expired_date }}</p>
          </div>
          <!-- <ul class="list-group list-group-flush">
                        <li class="list-group-item" style="font-weight: bold;">{{voucher.name}}</li>
                        <li class="list-group-item">
                            <ng-template [ngIf]="(voucher.type == 'percentage')">
                                <p class="card-text" style="font-weight: bold; margin-bottom: 0px;">Diskon {{voucher.value}}%</p>
                            </ng-template>
                            <ng-template [ngIf]="(voucher.type == 'numeric')">
                                <p class="card-text" style="font-weight: bold; margin-bottom: 0px;">Diskon Rp {{voucherTransform(voucher.value)}}</p>
                            </ng-template>
                            <ng-template [ngIf]="(voucher.min_price)">
                                <p class="card-text" style="margin-bottom: 0px;">Min. Blj Rp{{voucherTransform(voucher.min_price)}}</p>
                            </ng-template>
                            <ng-template [ngIf]="(voucher.max_discount)">
                                <p class="card-text" style="margin-bottom: 0px;">Max. Disc Rp{{voucherTransform(voucher.max_discount)}}</p>
                            </ng-template>
                            <p class="card-text" style="margin-bottom: 0px;">S/D: {{voucher.expired_date}}</p>
                        </li>
                    </ul> -->
          <!-- <div class="card-body">
                        <p class="card-text">{{voucher.expired_date}}</p>
                        <ng-template [ngIf]="(voucher.min_price)">
                            <p class="card-text">Min. priceTransform({{voucher.min_price}})</p>
                        </ng-template>
                        <ng-template [ngIf]="(voucher.max_discount)">
                            <p class="card-text">Max. priceTransform({{voucher.max_discount}})</p>
                        </ng-template>
                    </div> -->
          <!-- <a class="btn btn-primary" style="color: #c48811; padding-top: 20px;" (click)="onSelectVoucher(voucher.voucher_id)">Klaim</a> -->
        </div>
        <div class="text-center" style="margin-top: 30px">
          <div *ngIf="allowClaim; else elseBlock">
            <button
              class="btn btn-action"
              style="background: #3571b6 !important"
              (click)="onSelectVoucher(voucher)"
            >
              Klaim
            </button>
          </div>
          <ng-template #elseBlock>
            <button
              class="btn btn-action isDisabled"
              style="background: #3571b6 !important; cursor: not-allowed"
            >
              Klaim
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="isLoading">
  <div class="col ptb_50" style="text-align: center">
    <mat-spinner style="margin: 80px auto"></mat-spinner><br />
    <h4>Loading</h4>
  </div>
</ng-template>
