import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { PRODUCT } from 'src/app/components/models/order-management/order-management';
import { createReview } from 'src/app/components/models/review-and-rating/review-and-rating.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class RatingAndReviewComponent implements OnInit {

  reviewForm: FormGroup;
  uploadLoading
  // reviews = []
  constructor(
    public dialogRef: MatDialogRef<RatingAndReviewComponent>,
    private dialog: MatDialog,
    private service: ApiService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PRODUCT,
  ) {
    this.reviewForm = this.fb.group({
      reviews: this.fb.array([])
    });
  }

  ngOnInit(): void {
    console.log(this.data)
    this.data.invoice_items.map(item => {
      this.reviews.push(this.fb.group({
        name: [item.name],
        variant_name: [item.variant_name],
        quantity: [item.quantity],
        product_id: [item.product_id],
        photo: [item.photo_url[0]],
        rating: [item.review, [Validators.required, Validators.min(1), Validators.max(5)]],
        description: ['', Validators.required],
        invoice_number: [this.data.invoice],
        hide_username: [false],
        images: this.fb.array([]),
        video: [null]
      }));

    })
  }

  get reviews(): FormArray {
    return this.reviewForm.get('reviews') as FormArray;
  }

  selectStar(reviewIndex: number, rating: number) {
    const reviewFormGroup = this.reviews.at(reviewIndex) as FormGroup;
    reviewFormGroup.get('rating')?.setValue(rating);
  }

  transformStar(star: number) {
    switch (star) {
      case 1:
        return "sangat kecewa"
      case 2:
        return "kecewa"
      case 3:
        return "sedikit kecewa"
      case 4:
        return "puas"
      case 5:
        return "sangat puas"
    }
  }

  handleImageUpload(reviewIndex, files: FileList) {
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    const file = files.item(0);

    if (!file) return;

    if (file.size > maxSizeInBytes) {
      Swal.fire({
        title: "Upload Error",
        text: "Ukuran File Melebihi 5mb",
        icon: "error",
      })
    }

    const formData = new FormData();
    formData.append('images', file);

    const reviewFormGroup = this.reviews.at(reviewIndex) as FormGroup;
    const imagesArray = reviewFormGroup.get('images') as FormArray;

    if (reviewFormGroup.get('images') instanceof FormArray) {
      console.log('Images is a FormArray');
    } else {
      console.error('Images is not a FormArray');
    }

    imagesArray.push(this.fb.group({
      status: ['uploading'],
      progress: [0],
      url: [''],
      filename: ['']
    }));
    this.uploadLoading = true
    const index = imagesArray.length - 1;
    this.service.uploadImages(formData).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        imagesArray.at(index).patchValue({
          status: 'error'
        });
        this.uploadLoading = false
        return throwError(error);
      })
    ).subscribe(e => {
      if (e.type === HttpEventType.UploadProgress) {
        imagesArray.at(index).patchValue({
          progress: Math.round((100 * e.loaded) / e.total)
        });
      } else if (e.type === HttpEventType.Response) {
        const response: any = e.body;
        imagesArray.at(index).patchValue({
          status: 'done',
          url: response.images[0].url,
          filename: response.images[0].filename
        });
        this.uploadLoading = false
      }
    })

  }

  viewImage(url: string) {
    window.open(url, '_blank');
  }

  deleteImage(reviewIndex, imageIndex) {
    const reviewFormGroup = this.reviews.at(reviewIndex) as FormGroup;
    const imagesArray = reviewFormGroup.get('images') as FormArray;

    imagesArray.removeAt(imageIndex);

  }


  handleVideoUpload(reviewIndex, files: FileList) {
    const file = files.item(0);

    if (!file) return;

    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      console.log(video.duration)
      if (video.duration > 30) {
        Swal.fire({
          title: "Upload Error",
          text: "Maksimal durasi video 30 detik",
          icon: "error",
        })
        return
      }
      else {
        const formData = new FormData();
        formData.append('video', file);

        const reviewFormGroup = this.reviews.at(reviewIndex) as FormGroup;
        reviewFormGroup.get('video').setValue({
          status: "uploading",
          progress: 0
        })
        this.uploadLoading = true

        this.service.uploadVideo(formData).pipe(
          catchError((error: HttpErrorResponse) => {
            reviewFormGroup.get('video').patchValue({
              status: "error",
              progress: 0
            })
            console.log(error)
            this.uploadLoading = false
            return throwError(error);
          })
        ).subscribe(e => {
          if (e.type === HttpEventType.UploadProgress) {
            reviewFormGroup.get('video').patchValue({
              status: "uploading",
              progress: Math.round((100 * e.loaded) / e.total)
            })

          } else if (e.type === HttpEventType.Response) {
            const response: any = e.body;
            reviewFormGroup.get('video').patchValue({
              status: 'done',
              url: response.url,
              filename: response.filename
            })
            this.uploadLoading = false
          }
        })
      }
    }

    video.src = URL.createObjectURL(file);
  }

  viewVideo(url: string) {
    window.open(url, '_blank');
  }

  deleteVideo(reviewIndex) {
    const reviewFormGroup = this.reviews.at(reviewIndex) as FormGroup;
    reviewFormGroup.get('video').setValue(null)

  }

  submit() {
    console.log(this.reviewForm)
    const payload: createReview[] = this.reviewForm.value.reviews.map(item => {
      const review: createReview = {
        product_id: parseInt(item.product_id),
        rating: item.rating,
        description: item.description,
        invoice_number: item.invoice_number,
        hide_username: item.hide_username
      }

      if (item.images.length > 0) {
        review.images = item.images.map(image => image.filename)
      }

      if (item.video) {
        review.video = item.video.filename
      }
      return review
    })
    console.log(payload)

    this.service.addReview(payload).subscribe((r) => {
      this.dialogRef.close()
    },
      err => {
        console.error(err)
        Swal.fire({
          title: "Error",
          text: err.error.message,
          icon: "error",
        })
      }
    )
  }
}
